import React from 'react';
import {
  Icon,
  bitcoinsvIcon,
} from '../config/icons';

export const globals = {
  // Basic strings
  homePosts: 'home-posts',
  allTime: 'all-time',
  newest: 'newest',
  trending: 'trending',
  week: 'week',
  followingPosts: 'following-posts',
  noContentToDisplay: "You've reached the end for this type of content",
  noContentAvailable: "Nothing to find here yet. YOU could be the first.",
  memberCommentCost: .01,
  voteCost: .005,
  memberStampshotCost: .01,
  nonMemberStampshotCost: .03,
  thumbsCostMin: .05,
  thumbsCostMax: 1.00,
  listAndPollThumbsCost: .02,
  listCommentCost: .02,
  promotionsPrice: 15,
  bitcoinBusinessesPrice: 10,
  commentCost: .05,
  followingCost: .05,
  membershipPrice: 35.00,
  oneYearMembershipPrice: 35.00,
  lifeTimeMembershipPrice: 80.00,
  shareCost: .02,
  fetchTwenty: 20,
  fetchFifty: 50,
  platformName: 'TipStampers.com',
  listCategoryArray: ['lists'],
  nonStandardCategoriesArray: ['lists', 'polls', 'questions'],
  wrongEmbedFormatError: `Incorrect URL format. See FAQ Page for correct formats.`,
  // The ones I decided to be free to ignite usage, but changed my mind because of the #ListStamp campaign
  // freeCategoryCostArray: [`meet-the-stampers`, `water-cooler`, `stamp-you`, `teach`, `gaming`, `music`, `podcasts`, `entrepreneurship`,
  //   `career`, `cooking`, `fitness`, `ama`, `interviews`, `tutorials`, `articles`, `chatgpt`, `reviews`, `life`, `health`, `code-share`, `books`,
  //   `stories`, `letter-forever`, `poetry`
  // ],
  freeCategoryCostArray: [`meet-the-stampers`, `water-cooler`],
  fiveCentCategoryCostArray: [`stamp-you`],
  twentyCentCategoryCostArray: [`bsv-news`, `in-the-news`],
  twentyFiveCentCategoryCostArray: [`lists`],
  fiftyCentCategoryCostArray: [`prompts`, `bounties`, `prompt-games`],
  initialTipDocument: {
    tipMessage: `Welcome to TipStampers.com! Here’s a penny tip you can use to vote on Polls. For more free tips, create an introductory post in “Meet the Stampers” then use that seed money to post content and earn *100% cut* thereafter. Paywalls included. Good luck, have fun, and keep it classy. Thanks for checking us out.`,
    tipAmount: .01,
    tipperHandCashHandle: 'TipStampers',
    tipperHandCashAvatarURL: 'https://cloud.handcash.io/v2/users/profilePicture/TipStampers',
    tipperHandCashId: '',
    actionTakerIsMember: true
  },
  updateExistingArray(existingArray, newData, paginationAmount) {
    if (!existingArray || !newData || !paginationAmount || ![20, 50].includes(paginationAmount)) return []
    let addingToArray = existingArray
    addingToArray.forEach(post => {
      post.ref = false
      post.lastItem = false
    })
    // Add the new items to the array
    addingToArray = addingToArray.concat(newData)
    // Add ref, if applicable
    if (paginationAmount === 20 && newData.length === globals.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }
    if (paginationAmount === 50 && newData.length === globals.fetchFifty) {
      let itemThirtyFive = addingToArray[addingToArray.length - 5]
      itemThirtyFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemThirtyFive)
    }
    // Add lastItem property (for displaying noMoreResults & Loading)
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  },
  // Regex for string links.
  checkForLinksToAdd(string) {
    if (!string) return
    let content = string
    // The Regex grabs all the URLS, so we need to remove the URLS from iframe videos (bdi included), <a> tags and <img> tags
    const iframeRegex = /<iframe[^>]*>[\s\S]*?<\/iframe>/gi;
    const bdiRegex = /<bdi[^>]*>[\s\S]*?<\/bdi>/gi;
    const aHrefRegex = /<a[^>]*>[\s\S]*?<\/a>/gi;
    const imageRegex = /<img[^>]*>/gi;
    let noIframeContentOrLinks = content.replace(iframeRegex, '');
    noIframeContentOrLinks = noIframeContentOrLinks.replace(bdiRegex, '');
    noIframeContentOrLinks = noIframeContentOrLinks.replace(aHrefRegex, '');
    noIframeContentOrLinks = noIframeContentOrLinks.replace(imageRegex, '');
    // regexString defines a regular expression pattern for matching URLs.
    const regexString = '\\b(https?|ftp|file):\\/\\/[a-zA-Z0-9+&@#\\/%?=~_|!:,.;-]*[a-zA-Z0-9+&@#\\/%=~_|]';
    // The pattern breakdown:
    // - \\b asserts a word boundary (ensures the URL starts at a word boundary).
    // - (https?|ftp|file) matches the URL scheme, allowing http, https, ftp, or file.
    // - :\\/\\/: matches the colon and two forward slashes after the scheme.
    // - [a-zA-Z0-9+&@#\\/%?=~_|!:,.;-]* matches the URL path and query parameters.
    // - [a-zA-Z0-9+&@#\\/%=~_|] ensures the URL ends with an alphanumeric character or specific allowed characters.
    const regex = new RegExp(regexString, 'gi');
    // Apply the regular expression to the noIframeContentOrLinks string to find all matching URLs.
    const urlMatches = noIframeContentOrLinks.match(regex);
    // If urlMatches exist, conduct the function
    if (urlMatches && urlMatches.length > 0) {
      // Use a temporary placeholder for each URL during replacement
      const placeholders = [];
      // Replace each URL with a unique placeholder
      urlMatches.forEach((url, index) => {
        const placeholder = `__TEMP_URL_PLACEHOLDER_${index}__`;
        placeholders.push(placeholder);
        const isInsideATag = content.includes(`<a href="${url}"`) || content.includes(`<a href='${url}'`);
        if (!isInsideATag) {
          content = content.replace(url, placeholder);
        }
      });

      // Iterate through the placeholders and replace each with the link tag
      placeholders.forEach((placeholder, index) => {
        const url = urlMatches[index];
        const linkTag = `<a rel="noopener noreferrer" href="${url}" target="_blank">${url}</a>`;
        content = content.replace(new RegExp(placeholder, 'g'), linkTag);
      });
    }
    return content
  },
  getTaggedHandles(content, authHandle) {
    if (!content) return
    let taggedHandlesArray = [];
    const regex = /@([a-zA-Z0-9]+)(?::|-|<|.|,|~|=)?/g;
    let match;
    while ((match = regex.exec(content)) !== null) {
      // match[0] contains the entire match, match[1] contains the username
      let handle = match[1]; // Extract the username without the optional colon, dash, or <
      if (handle.endsWith(`<`)) handle.slice(0, -1);
      if (handle.endsWith(`-`)) handle.slice(0, -1);
      if (handle.endsWith(`:`)) handle.slice(0, -1);
      if (handle.endsWith(`.`)) handle.slice(0, -1);
      if (handle.endsWith(`,`)) handle.slice(0, -1);
      if (handle.endsWith(`~`)) handle.slice(0, -1);
      if (handle.endsWith(`=`)) handle.slice(0, -1);
      // If the person is not tagging themselves, we add to array
      if (handle !== authHandle) taggedHandlesArray.push(handle);
    }
    // Create a new Set to remove duplicates
    if (taggedHandlesArray.length > 0) taggedHandlesArray = [...new Set(taggedHandlesArray)]
    return taggedHandlesArray
  },
  // Button Ripple function
  createRipple(event) {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;
    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
    circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`;
    circle.classList.add("ripple");
    const ripple = button.getElementsByClassName("ripple")[0];
    if (ripple) {
      ripple.remove();
    }
    button.appendChild(circle);
  },
  // addTargetBlankAndRelToHref(string) {
  //   let newString = string
  //   newString.replaceAll('<a', `<a target="_blank"`)
  //   return newString
  // },
  // Toast notifications
  toastSuccess(toast, message) {
    toast.success(message)
  },
  toastError(toast, message) {
    toast.error(message)
  },
  // Category stuff
  // categoryHrefs are for validation in MainPageContainer within fetchCategoryData function
  categoryHrefs: [
    'all',
    'communities',
    'prompt-games',
    'stamp-you',
    'meet-the-stampers',
    // Entertainment +
    'gaming',
    'music',
    'podcasts',
    'teach',
    'comedy',
    'sports',
    'fitness',
    'cooking',
    'talent-showcase',
    'animals',
    'art',
    'beautiful-nature',
    'panoramic-reels',
    'tiktok-playlist',
    // Professional
    'entrepreneurship',
    'ama',
    'career',
    'interviews',
    'in-the-news',
    'bitcoin-businesses',
    'promotions',
    // Knowledge
    'tutorials',
    'articles',
    'chatgpt',
    'science',
    'reviews',
    'life',
    'health',
    'code-share',
    'books',
    // Bonus
    'bitcoin-stories',
    'polls',
    'lists',
    'questions',
    'stories',
    'letter-forever',
    'poetry',
    'bounties',
    // Water cooler
    'water-cooler',
    // No longer used on frontend
    'prompts',
    'bsv-news',
    'writing-games',
  ],
  // The following sets the state for the particular category within the fetchCategoryData function too
  all: {
    categoryName: 'All',
    href: 'all',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10,
    }
  },
  communities: {
    categoryName: 'Communities',
    href: 'communities',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: 10,
    }
  },
  writingGames: {
    categoryName: 'Writing Games',
    href: 'writing-games',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .05,
    }
  },
  promptGames: {
    categoryName: 'Prompt Games',
    href: 'prompt-games',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .50,
    }
  },
  stampYou: {
    categoryName: 'Stamp You',
    href: 'stamp-you',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .05,
    }
  },
  meetTheStampers: {
    categoryName: 'Meet the Stampers',
    href: 'meet-the-stampers',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: 0,
    }
  },
  // TipToks & More
  panoramicReels: {
    categoryName: 'Panoramic Reels',
    href: 'panoramic-reels',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  tiktokPlaylist: {
    categoryName: 'TikTok Playlist',
    href: 'tiktok-playlist',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  teach: {
    categoryName: `Teach & Learn`,
    href: 'teach',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  cooking: {
    categoryName: `Cooking`,
    href: 'cooking',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  fitness: {
    categoryName: 'Fitness',
    href: 'fitness',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  gaming: {
    categoryName: 'Twitch Gaming',
    href: 'gaming',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  talentShowcase: {
    categoryName: 'Talent Showcase',
    href: 'talent-showcase',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  comedy: {
    categoryName: 'Comedy',
    href: 'comedy',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  beautifulNature: {
    categoryName: 'Beautiful Nature',
    href: 'beautiful-nature',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  art: {
    categoryName: 'Art',
    href: 'art',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  animals: {
    categoryName: 'Animal Love',
    href: 'animals',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  sports: {
    categoryName: 'Sports',
    href: 'sports',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  music: {
    categoryName: 'Spotify Soundcloud',
    href: 'music',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  // Professional
  entrepreneurship: {
    categoryName: 'Entrepreneurship',
    href: 'entrepreneurship',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  career: {
    categoryName: 'Business & Career',
    href: 'career',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  promotions: {
    categoryName: 'Promotions',
    href: 'promotions',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: 15
    }
  },
  inTheNews: {
    categoryName: `In The News`,
    href: 'in-the-news',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .20
    }
  },
  interviews: {
    categoryName: `Interviews`,
    href: 'interviews',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  ama: {
    categoryName: `AMA`,
    href: 'ama',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  appsAndBusinessNews: {
    categoryName: 'BSV Apps & Business',
    href: 'bsv-news',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .20
    }
  },
  bitcoinBusinesses: {
    categoryName: 'Bitcoin Businesses',
    href: 'bitcoin-businesses',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: 10
    }
  },
  // Knowledge
  tutorials: {
    categoryName: `How To Tutorials`,
    href: 'tutorials',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  articles: {
    categoryName: 'Articles',
    href: 'articles',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  chatGPT: {
    categoryName: 'ChatGPT & AI',
    href: 'chatgpt',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  science: {
    categoryName: 'Science',
    href: 'science',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  reviews: {
    categoryName: `Reviews`,
    href: 'reviews',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  life: {
    categoryName: `Life`,
    href: 'life',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  health: {
    categoryName: `Health & Diet`,
    href: 'health',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  codeShare: {
    categoryName: 'Code Share',
    href: 'code-share',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  books: {
    categoryName: `Books`,
    href: 'books',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  // Bonus
  polls: {
    categoryName: 'Polls',
    href: 'polls',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  lists: {
    categoryName: 'Lists',
    href: 'lists',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .25
    }
  },
  questions: {
    categoryName: 'Questions',
    href: 'questions',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  podcasts: {
    categoryName: 'Podcasts',
    href: 'podcasts',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  stories: {
    categoryName: 'Stories',
    href: 'stories',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  bitcoinStories: {
    categoryName: 'Bitcoin Stories',
    href: 'bitcoin-stories',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  letterForever: {
    categoryName: 'Letter Forever',
    href: 'letter-forever',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  poetry: {
    categoryName: 'Poetry',
    href: 'poetry',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .10
    }
  },
  prompts: {
    categoryName: 'Bounty Challenges',
    href: 'prompts',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .50
    }
  },
  bounties: {
    categoryName: 'Bounty Challenges',
    href: 'bounties',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: .50
    }
  },
  waterCooler: {
    categoryName: 'Water Cooler',
    href: 'water-cooler',
    tags: [],
    settings: {
      isPaywallAvailable: true,
      costToPost: 0,
    }
  }
};
export function iframeUpdate() {
  document.querySelectorAll('bdi[src]').forEach(element => {
    // Grab URL and create elements
    const url = element.getAttribute('src');
    // Update elements classes & attributes
    let iframeElement = document.createElement('iframe');
    const classesForIframe = getClassesForIFrameWithUrlForDisplay(url)
    // Add container div classes, if applicable
    iframeElement.setAttribute('class', classesForIframe)
    iframeElement.setAttribute('title', url);
    iframeElement.setAttribute('src', url);
    iframeElement.setAttribute('allow', 'accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture')
    iframeElement.setAttribute('allowFullScreen', true);
    iframeElement.setAttribute('loading', 'lazy');
    // Replace original bdi tag with newly created element
    element.parentNode.replaceChild(iframeElement, element);
  });
}
function getClassesForIFrameWithUrlForDisplay(url) {
  let classes = 'standard-iframe'
  const standardIFrame = [
    `youtube.com/embed`,
    `player.vimeo.com/`, `player.twitch.tv/`
  ]
  // YouTube, Vimeo, Spotify, Twitch
  if (standardIFrame.some(urlInArray => url.includes(urlInArray))) {
    classes = `standard-iframe rounded-lg media-shadow`
    return classes
  }
  // Spotify
  if (url.includes(`spotify.com/embed/track`)) {
    classes = `spotify-iframe rounded-xl media-shadow`
    return classes
  }
  // Soundcloud
  if (url.includes(`soundcloud.com/player/`)) {
    classes = `soundcloud-iframe rounded-lg media-shadow`
    return classes
  }
  // Apple Podcast
  if (url.includes(`podcasts.apple.com/`)) {
    classes = `w-full rounded-lg media-shadow`
    return classes
  }
  // // Twitter
  // if (url.includes(`twitter.com`)) {
  //   classes = `twitter-iframe media-shadow`
  //   return classes
  // }
  // TikTok
  if (url.includes(`tiktok.com/embed/`)) {
    classes = `tiktok-iframe`
    return classes
  }
  // Facebook
  if (url.includes(`https://www.facebook.com/plugins/video.php?href=`)) {
    classes = `facebook-iframe rounded-lg media-shadow`
    return classes
  }
  // Instagram
  if (url.includes(`instagram.com/reel`)) {
    classes = `instagram-iframe rounded-lg media-shadow`
    return classes
  }
  return classes
}
// Quill Editor
export function getClassesForIFrameWithUrlInEditor(url) {
  let classes = { iframeContainer: 'i-container my-2', iframe: 'standard-iframe rounded-lg media-shadow' }
  const standardIFrame = [`youtube.com/embed`, `player.vimeo.com/`, `player.twitch.tv/`]
  // YouTube, Twitch, Vimeo, 
  if (standardIFrame.some(urlInArray => url.includes(urlInArray))) {
    classes = { iframeContainer: `i-container my-2`, iframe: `standard-iframe rounded-lg media-shadow` }
    return classes
  }
  const spotifyIFrame = [`spotify.com/embed/track`, `spotify.com/embed/episode`]
  if (spotifyIFrame.some(urlWithinArray => url.includes(urlWithinArray))) {
    classes = { iframeContainer: `invis-container my-2`, iframe: `spotify-iframe rounded-xl media-shadow` }
    return classes
  }
  if (url.includes(`spotify.com/embed/album`) || url.includes(`spotify.com/embed/playlist`)) {
    classes = { iframeContainer: `invis-container my-2`, iframe: `spotify-iframe-album rounded-xl media-shadow` }
    return classes
  }
  // Soundcloud
  if (url.includes(`soundcloud.com/player/`)) {
    classes = { iframeContainer: `invis-container my-2`, iframe: `soundcloud-iframe rounded-lg media-shadow` }
    return classes
  }
  // Apple Podcast
  if (url.includes(`podcasts.apple.com/`)) {
    classes = { iframeContainer: `invis-container my-2`, iframe: `w-full rounded-lg media-shadow` }
    return classes
  }
  // // Twitter
  // if (url.includes(`twitter.com`)) {
  //   classes = { iframeContainer: `twitter-container my-2`, iframe: `twitter-iframe media-shadow` }
  //   return classes
  // }
  // TikTok
  if (url.includes(`tiktok.com/embed/`)) {
    classes = { iframeContainer: `tiktok-container my-2`, iframe: `tiktok-iframe` }
    return classes
  }
  // Facebook
  if (url.includes(`https://www.facebook.com/plugins/video.php?href=`)) {
    classes = { iframeContainer: `facebook-container my-2`, iframe: `facebook-iframe` }
    return classes
  }
  // Instagram
  if (url.includes(`instagram.com/reel`)) {
    classes = { iframeContainer: `instagram-container my-2`, iframe: `instagram-iframe` }
    return classes
  }
  return classes
}
// export async function tikTokFunction(url) {
//   console.log(url)
//   fetch(`https://www.tiktok.com/oembed?url=${url}`)
//     .then(res => res.json())
//     .then(data => {
//       console.log(data)
//       const tikTokId = data.embed_product_id
//       console.log(tikTokId)
//       return `https://www.tiktok.com/embed/${tikTokId}/`
//     })
//     .catch(err => { return null })
// }
// Quill Editor
export function getVideoUrl(url) {
  let match
  // YouTube
  if (url.includes(`youtu`)) {
    match = url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/) ||
      url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/) ||
      url.match(/^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#&?]*).*/);
    if (match && match[2].length === 11) {
      return 'https://www.youtube.com/embed/' + match[2] + '?showinfo=0';
    }
  }
  // Vimeo
  if (url.includes(`vimeo.com/`)) {
    const vimeoRegex = new RegExp(/(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/)
    if (url.match(vimeoRegex)) {
      match = url.match(vimeoRegex)
      if (match[5] == null || match[5] === undefined || match[5] === '' || match[5].length === 0) return null
      return 'https://player.vimeo.com/video/' + match[5];
    }
  }
  // Twitch
  if (url.includes(`twitch.tv/`)) {
    const twitchRegex = new RegExp(/(twitch\.tv\/videos\/|player\.twitch\.tv\/\?video=v)([0-9]{1,12})/)
    if (url.match(twitchRegex)) {
      match = url.match(twitchRegex)
      // For localhost testing
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      // return `https://player.twitch.tv/?video=v${match[2]}&parent=localhost&autoplay=false`
      // For actual production
      return `https://player.twitch.tv/?video=v${match[2]}&parent=tipstampers.com&parent=www.tipstampers.com&autoplay=false`
    }
  }
  // Instagram Reels (and Reels only, as you have to login for other stuff like posts.)
  if (url.includes(`instagram.com/`) || (url.includes(`instagr.am/`) && url.includes(`reel`))) {
    const instagramRegex = new RegExp(/https?:\/\/(?:www.)?(instagram.com|instagr.am)\/reel\/([^/?#&]+).*/)
    if (url.includes(`instagram.com/p/`)) {
      const regex = /instagram\.com\/p\/(.+)\//;
      match = url.match(regex);
      if (match) {
        const urlData = match[1];
        return `https://www.instagram.com/reel/${urlData}/embed`
      }
      return null
    }
    if (url.match(instagramRegex)) {
      match = url.match(instagramRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      return `https://www.instagram.com/reel/${match[2]}/embed`
    }
  }
  // Spotify Tracks
  const spotifyTrackTest = 'open.spotify.com/track/'
  const spotifyEmbedTest = 'open.spotify.com/embed/track/'
  if (url.includes(spotifyTrackTest) || url.includes(spotifyEmbedTest)) {
    const spotifyTrackRegex = new RegExp(/(spotify\.com\/track\/|spotify\.com\/embed\/track\/)([a-zA-Z0-9]+)/)
    if (url.match(spotifyTrackRegex)) {
      match = url.match(spotifyTrackRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      return `https://open.spotify.com/embed/track/${match[2]}?utm_source=generator`
    }
  }
  // Spotify Albums
  // https://open.spotify.com/album/6tyrk5iOJua2CmntSpuwx6?si=9IhHrEUvRfaBHI2GFBZXIg&context=spotify%3Aalbum%3A6tyrk5iOJua2CmntSpuwx6
  // https://open.spotify.com/embed/album/6tyrk5iOJua2CmntSpuwx6?utm_source=generator
  const spotifyAlbumTest = 'open.spotify.com/album/'
  const spotifyAlbumEmbedTest = 'open.spotify.com/embed/track/'
  if (url.includes(spotifyAlbumTest) || url.includes(spotifyAlbumEmbedTest)) {
    const spotifyAlbumRegex = new RegExp(/(spotify\.com\/album\/|spotify\.com\/embed\/album\/)([a-zA-Z0-9]+)/)
    if (url.match(spotifyAlbumRegex)) {
      match = url.match(spotifyAlbumRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      return `https://open.spotify.com/embed/album/${match[2]}?utm_source=generator`
    }
  }
  // Spotify Playlist
  // https://open.spotify.com/playlist/37i9dQZF1DWWQRwui0ExPn?si=56d094c77fb042b0
  const spotifyPlaylistTest = 'open.spotify.com/playlist/'
  const spotifyEmbedPlaylistTest = 'open.spotify.com/playlist/'
  if (url.includes(spotifyPlaylistTest) || url.includes(spotifyEmbedPlaylistTest)) {
    const spotifyPlaylistRegex = new RegExp(/(spotify\.com\/playlist\/|spotify\.com\/embed\/playlist\/)([a-zA-Z0-9]+)/)
    if (url.match(spotifyPlaylistRegex)) {
      match = url.match(spotifyPlaylistRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      return `https://open.spotify.com/embed/playlist/${match[2]}?utm_source=generator`
    }
  }
  // Spotify Podcasts
  // https://open.spotify.com/episode/7edwvm2c6Ieuzun4xtFYCJ?si=DgIOxbqLTtOCY3Vm1SLuRA
  const spotifyPodcastTest = 'open.spotify.com/episode/'
  const spotifyEmbedPodcastTest = 'open.spotify.com/episode/'
  if (url.includes(spotifyPodcastTest) || url.includes(spotifyEmbedPodcastTest)) {
    const spotifyPodcastRegex = new RegExp(/(spotify\.com\/episode\/|spotify\.com\/embed\/episode\/)([a-zA-Z0-9]+)/)
    if (url.match(spotifyPodcastRegex)) {
      match = url.match(spotifyPodcastRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      return `https://open.spotify.com/embed/episode/${match[2]}?utm_source=generator`
    }
  }
  // Apple podcast
  if (url.includes(`podcasts.apple.com/`) && url.includes(`/podcast/`) && url.includes(`/id`) && url.includes(`?i=`) && url.includes(`https://`)) {
    const applePodcastLink = url.split('podcasts.apple.com/').pop()
    return `https://embed.podcasts.apple.com/${applePodcastLink}`
  }
  // Soundcloud
  if (url.includes(`soundcloud.com/`)) {
    const soundCloudRegex = new RegExp(/(soundcloud\.com\/|https%3A\/\/soundcloud.com\/)([\w\-.]+)(?:\/)+([\w\-.]+)/)
    if (url.match(soundCloudRegex)) {
      match = url.match(soundCloudRegex)
      if (match[2] == null || match[2] === undefined || match[2] === '' || match[2].length === 0) return null
      if (match[3] == null || match[3] === undefined || match[3] === '' || match[3].length === 0) return null
      return `https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/${match[2]}/${match[3]}`
    }
  }
  // TikTok
  const tikTokLinkCheck = [`tiktok.com/v/`, `tiktok.com/video/`, `tiktok.com/embed/`, `tiktok.com/trending`, `tiktok.com/@`]
  if (tikTokLinkCheck.some(string => url.includes(string))) {
    const tikTokRegex = new RegExp(/(@[a-zA-z0-9]*|.*)(\/.*\/|trending.?shareId=)([\d]*)/)
    if (url.match(tikTokRegex)) {
      match = url.match(tikTokRegex)
      // This is in case someone uses an @ and it has to do with the user, rather than an actual post.
      if (match[3] == null || match[3] === undefined || match[3] === '' || match[3].length === 0) return null
      return `https://www.tiktok.com/embed/v2/${match[3]}`
    }
  }
  // if (url.includes(`www.tiktok.com/t/`)) {
  //   let thing = tikTokFunction(url)
  //   console.log(thing)
  //   return thing

  // }
  // // Twitter
  // if (url.includes(`twitter.com/`)) {
  //   const twitterRegex = new RegExp(/twitter\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)/)
  //   if (url.match(twitterRegex)) {
  //     match = url.match(twitterRegex)
  //     console.log(match)
  //     // This is in case someone uses an @ and it has to do with the user, rather than an actual post.
  //     if (match[1] == null || match[1] === undefined || match[1] === '' || match[1].length === 0) return null
  //     if (match[3] == null || match[3] === undefined || match[3] === '' || match[3].length === 0) return null
  //     return `https://twitframe.com/show?url=https%3A%2F%2Ftwitter.com%2F${match[1]}%2Fstatus%2F${match[3]}`
  //   }
  // }
  // Facebook
  const faceBookLinkCheck = [`fb.watch/`, `facebook.com/`]
  if (faceBookLinkCheck.some(string => url.includes(string))) {
    if (url.includes(`fb.watch/`)) {
      // This function splits @ fb.watch/, then returns the last element of the array, then it splits again at the /, to get the ID
      const facebookId = url.split('fb.watch/').pop().split(`/`)
      if (facebookId[0].length === 0 || facebookId[0] == null || facebookId[0] === undefined || facebookId[0] === '') return null
      return `https://www.facebook.com/plugins/video.php?href=https://fb.watch/${facebookId[0]}/`
    }
    const facebookRegex = new RegExp(/(\/videos\/|\/watch\/\?v=|\/watch\/\?ref=search&v=|\/video\/embed\?video_id=|href=https%3A%2F%2Fwww\.facebook\.com%2Fwatch%2F%3Fv%3D)(t\.\[0-9]+\/)?(\d+)/)
    if (url.match(facebookRegex)) {
      match = url.match(facebookRegex)
      return `https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fwatch%2F%3Fv%3D${match[3]}%20`
    }
    //  'https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fcharliethegolden18%2Fvideos%2F446134817042483%2F&show_text=false&width=267&t=0" 
  }
  return null;
}

export const IntroText = (props) => {
  // Example
  // const communityArray = [
  //   {
  //     label: `Name`,
  //     href: `href`
  //   },
  // ]
  const bitcoinSVArray = [
    {
      label: 'Bitcoin (SV) Supporters',
      href: `https://tipstampers.com/posts/bitcoin-sv-supporters-on-tipstampers`
    },
    {
      label: 'Bitcoin Story Sharers',
      href: `https://tipstampers.com/posts/bitcoin-story-sharers-on-tipstampers`
    },
    {
      label: `Bitcoin (SV) Content Creators`,
      href: `https://tipstampers.com/posts/bitcoin-sv-content-creators-on-tipstampers`
    },
    {
      label: `Bitcoin (SV) Coders`,
      href: `https://tipstampers.com/posts/bitcoin-sv-coders-on-tipstampers`
    },
    {
      label: `Bitcoin (SV) Entrepreneurs`,
      href: `https://tipstampers.com/posts/bitcoin-sv-entrepreneurs-on-tipstampers`
    },
    {
      label: `Haste Arcade Gamers`,
      href: `https://tipstampers.com/posts/haste-arcade-gamers-on-tipstampers`
    },
  ]
  const onlineEducationArray = [
    {
      label: `Teachers`,
      href: `https://tipstampers.com/posts/teachers-on-tipstampers`
    },
    {
      label: `Article Publishers`,
      href: `https://tipstampers.com/posts/article-publishers-on-tipstampers`
    },
    {
      label: `Online Educators`,
      href: `https://tipstampers.com/posts/online-educators-on-tipstampers`
    },
    {
      label: `ChatGPT Teachers`,
      href: `https://tipstampers.com/posts/chatgpt-teachers-on-tipstampers`
    },
    {
      label: `Podcasters`,
      href: `https://tipstampers.com/posts/podcasters-on-tipstampers`
    },
  ]
  const writingAndLiteratureArray = [
    {
      label: `Bloggers`,
      href: `https://tipstampers.com/posts/bloggers-on-tipstampers`
    },
    {
      label: `Story-tellers`,
      href: `https://tipstampers.com/posts/story-tellers-on-tipstampers`
    },
    {
      label: `Journalers`,
      href: `https://tipstampers.com/posts/journalers-on-tipstampers`
    },
    {
      label: `Authors`,
      href: `https://tipstampers.com/posts/authors-on-tipstampers`
    },
    {
      label: `Poets`,
      href: `https://tipstampers.com/posts/poets-on-tipstampers`
    },
  ]
  const technologyArray = [
    {
      label: `Coders`,
      href: `https://tipstampers.com/posts/coders-on-tipstampers`
    },
    {
      label: `Web Development Content Creators`,
      href: `https://tipstampers.com/posts/web-development-content-creators-on-tipstampers`
    },
    {
      label: `Blockchain Developers`,
      href: `https://tipstampers.com/posts/blockchain-developers-on-tipstampers`
    },
    {
      label: `ChatGPT & AI Users`,
      href: `https://tipstampers.com/posts/chatgpt-ai-users-on-tipstampers`
    },
    {
      label: `Tech Bloggers`,
      href: `https://tipstampers.com/posts/tech-bloggers-on-tipstampers`
    },
    {
      label: `Tech Geeks`,
      href: `https://tipstampers.com/posts/tech-geeks-on-tipstampers`
    },
    {
      label: `Vloggers`,
      href: `https://tipstampers.com/posts/vloggers-on-tipstampers`
    },
  ]
  const socialMediaArray = [
    {
      label: `X users`,
      href: `https://tipstampers.com/posts/x-users-on-tipstampers`
    },
    {
      label: `Facebook users`,
      href: `https://tipstampers.com/posts/facebook-users-on-tipstampers`
    },
    {
      label: `LinkedIn users`,
      href: `https://tipstampers.com/posts/linkedin-users-on-tipstampers`
    },
    {
      label: `Medium users`,
      href: `https://tipstampers.com/posts/medium-users-on-tipstampers`
    },
  ]
  const gamingArray = [
    {
      label: `Gamers`,
      href: `https://tipstampers.com/posts/gamers-on-tipstampers`
    },
    {
      label: `Old-School Console Gamers`,
      href: `https://tipstampers.com/posts/old-school-console-gamers-on-tipstampers`
    },
    {
      label: `WC3 Gamers`,
      href: `https://tipstampers.com/posts/wc3-gamers-on-tipstampers`
    },
    {
      label: `DOTA 2 Gamers`,
      href: `https://tipstampers.com/posts/dota-2-gamers-on-tipstampers`
    },
    {
      label: `League of Legends Gamers`,
      href: `https://tipstampers.com/posts/league-of-legends-gamers-on-tipstampers`
    },
    {
      label: `Counter-Strike Gamers`,
      href: `https://tipstampers.com/posts/counter-strike-gamers-on-tipstampers`
    },
    {
      label: `Fortnite Gamers`,
      href: `https://tipstampers.com/posts/fortnite-gamers-on-tipstampers`
    },
    {
      label: `Super Smash Bros Gamers`,
      href: `https://tipstampers.com/posts/super-smash-bros-gamers-on-tipstampers`
    },
  ]
  const platformSpecificArray = [
    {
      label: `Twitch Streamers`,
      href: `https://tipstampers.com/posts/twitch-streamers-on-tipstampers`
    },
    {
      label: `Apple Podcasters`,
      href: `https://tipstampers.com/posts/apple-podcasters-on-tipstampers`
    },
    {
      label: `Spotify Podcasters`,
      href: `https://tipstampers.com/posts/spotify-podcasters-on-tipstampers`
    },
    {
      label: `Spotify Musicians`,
      href: `https://tipstampers.com/posts/spotify-musicians-on-tipstampers`
    },
    {
      label: `SoundCloud Musicians`,
      href: `https://tipstampers.com/posts/soundcloud-musicians-on-tipstampers`
    },
    {
      label: `YouTube Creators`,
      href: `https://tipstampers.com/posts/youtube-creators-on-tipstampers`
    },
    {
      label: `TikTokers`,
      href: `https://tipstampers.com/posts/tiktokers-on-tipstampers`
    },
    {
      label: `Instagram Creators`,
      href: `https://tipstampers.com/posts/instagram-creators-on-tipstampers`
    },
  ]
  const businessAndEntreArray = [
    {
      label: `Entrepreneurs`,
      href: `https://tipstampers.com/posts/entrepreneurs-on-tipstampers`
    },
    {
      label: `LinkedIn Professionals`,
      href: `https://tipstampers.com/posts/linkedin-professionals-on-tipstampers`
    },
    {
      label: `Business Professionals`,
      href: `https://tipstampers.com/posts/business-professionals-on-tipstampers`
    },
    {
      label: `Workplace Leaders`,
      href: `https://tipstampers.com/posts/workplace-leaders-on-tipstampers`
    },
  ]
  const performanceArtsArray = [
    {
      label: `Entertainers`,
      href: `https://tipstampers.com/posts/entertainers-on-tipstampers`
    },
    {
      label: `Vocalists`,
      href: `https://tipstampers.com/posts/vocalists-on-tipstampers`
    },
    {
      label: `Instrumentalists`,
      href: `https://tipstampers.com/posts/instrumentalists-on-tipstampers`
    },
    {
      label: `Dancers`,
      href: `https://tipstampers.com/posts/dancers-on-tipstampers`
    },
    {
      label: `Bands`,
      href: `https://tipstampers.com/posts/bands-on-tipstampers`
    },
    {
      label: `Comedians`,
      href: `https://tipstampers.com/posts/comedians-on-tipstampers`
    },
    {
      label: `Skit Artists`,
      href: `https://tipstampers.com/posts/skit-artists-on-tipstampers`
    },
  ]
  const visualArtsArray = [
    {
      label: `Artists`,
      href: `https://tipstampers.com/posts/artists-on-tipstampers`
    },
    {
      label: `Photographers`,
      href: `https://tipstampers.com/posts/photographers-on-tipstampers`
    },
    {
      label: `Nature Enthusiasts`,
      href: `https://tipstampers.com/posts/nature-enthusiasts-on-tipstampers`
    },
  ]
  const healthAndWellnessArray = [
    {
      label: `Personal Trainers`,
      href: `https://tipstampers.com/posts/personal-trainers-on-tipstampers`
    },
    {
      label: `Fitness Teachers`,
      href: `https://tipstampers.com/posts/fitness-teachers-on-tipstampers`
    },
    {
      label: `Yoga Instructors`,
      href: `https://tipstampers.com/posts/yoga-instructors-on-tipstampers`
    },
    {
      label: `Pilates Instructors`,
      href: `https://tipstampers.com/posts/pilates-instructors-on-tipstampers`
    },
    {
      label: `Nutritionists`,
      href: `https://tipstampers.com/posts/nutritionists-on-tipstampers`
    },
    {
      label: `Health Educators`,
      href: `https://tipstampers.com/posts/health-educators-on-tipstampers`
    },
    {
      label: `Health Enthusiasts`,
      href: `https://tipstampers.com/posts/health-enthusiasts-on-tipstampers`
    },
  ]
  const foodAndCookingArray = [
    {
      label: `Chefs`,
      href: `https://tipstampers.com/posts/chefs-on-tipstampers`
    },
    {
      label: `Recipe Sharers`,
      href: `https://tipstampers.com/posts/recipe-sharers-on-tipstampers`
    },
    {
      label: `Natural Eating Recipe Creators`,
      href: `https://tipstampers.com/posts/natural-eating-recipe-creators-on-tipstampers`
    },
    {
      label: `Pintrest Cookers`,
      href: `https://tipstampers.com/posts/pintrest-cookers-on-tipstampers`
    },
    {
      label: `Instagram Cookers`,
      href: `https://tipstampers.com/posts/instagram-cookers-on-tipstampers`
    },
    {
      label: `Restaurant Reviewers`,
      href: `https://tipstampers.com/posts/restaurant-reviewers-on-tipstampers`
    },
    {
      label: `Food Critics`,
      href: `https://tipstampers.com/posts/food-critics-on-tipstampers`
    },
  ]
  const animalsArray = [
    {
      label: `Animal Video Creators`,
      href: `https://tipstampers.com/posts/animal-video-creators-on-tipstampers`
    },
    {
      label: `Cat Video Creators`,
      href: `https://tipstampers.com/posts/cat-video-creators-on-tipstampers`
    },
    {
      label: `Dog Video Creators`,
      href: `https://tipstampers.com/posts/dog-video-creators-on-tipstampers`
    },
  ]
  const miscArray = [
    {
      label: `Science Buffs`,
      href: `hhttps://tipstampers.com/posts/science-buffs-on-tipstampers`
    },
    {
      label: `Travel Bloggers`,
      href: `https://tipstampers.com/posts/travel-bloggers-on-tipstampers`
    },
    {
      label: `Movie Reviewers`,
      href: `https://tipstampers.com/posts/movie-reviewers-on-tipstampers`
    },
  ]
  return (
    <>
      <p className={`py-2 mb-3 mt-5 logo-style text-xl sm:text-2xl text-center
      gradient-background-eleven-no-hover shadow-effect-five rounded-xl`}>
        On TipStampers.com...
      </p>
      <p className={`mb-3`}>
        <span className={`font-bold`}>You </span>
        <span className={`font-bold`}>Build Brands</span>
        , <span className={`font-bold`}>Stamp Stories</span>
        , <span className={`font-bold`}>Teach</span>
        , <span className={`font-bold`}>Entertain</span>
        , <span className={`font-bold`}>Learn & Earn</span>
        . You earn <span
          className={`text-brand-green font-bold`}>100% cut</span>
        , directly from your audience, on demand, at the click of a button (assuming they find your content #tipworthy).
      </p>
      <p className={`mb-4`}>
        <span className={`font-bold`}>100% cut is available to all users of the platform</span>—including for paywalls—because
        instead of using credit cards, we use the ultra-cheap Bitcoin (SV) Blockchain as our payments network.
        This cuts out the middleman and keeps transaction costs free for you,
        so <a href='https://market.handcash.io/'
          aria-label={`Go to HandCash website`}
          className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          rel="noopener noreferrer"
          target="_blank"
        >Signup with HandCash</a> to take advantage of this today.
      </p>
      {/* Line break */}
      <p className={`py-2 mb-4 logo-style text-xl sm:text-2xl gradient-background-eleven-no-hover 
      shadow-effect-five rounded-xl text-center`}>
        Starting Out
      </p>
      <ul className={`mb-3`}>
        <li className={`text-lg`}>
          Check out the <span className={`logo-style`}>“Communities on TipStampers”</span> list below. Find a community you find interesting. Explore.
        </li>
      </ul>
      <p className={`mb-3 underline text-underline-under`}>
        Alternatively (and to start earning):
      </p>
      <ul className={`mb-5`}>
        <li>
          Introduce yourself for free within our <a href='https://tipstampers.com/categories/meet-the-stampers'
            aria-label={`Go to Meet the Stampers category on TipStampers.com`}
            className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          >“Meet the Stampers”
          </a> category for a minimum tip of $.05
        </li>
        <li>
          Compete in the  <a href='https://tipstampers.com/categories/prompt-games'
            aria-label={`Go to Prompt Games category on TipStampers.com`}
            className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          >TipStampers Prompt Games</a> for various rewards
        </li>
        <li>
          Share your Bitcoin Story within our <a href='https://tipstampers.com/categories/stamp-you'
            aria-label={`Go to Stamp You category on TipStampers.com`}
            className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          >“Stamp You”</a> blogging category for up to $1.00 in tips
        </li>
        {/* <li>
          Collect bounties by completing challenges within our <a
            href='https://tipstampers.com/categories/bounty-challenges'
            aria-label={`Go to Bounty Challenges category on TipStampers.com`}
            className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          >“Bounty Challenges”</a> category
        </li> */}
        <li>
          Stamp your content if you expect to earn more from it than our spam-filtering,
          price-to-post (which is <a href='https://tipstampers.com/membership'
            aria-label={`Go to Membership Page on TipStampers.com`}
            className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
          >free for members</a>)
        </li>
      </ul>
      {/* Break */}
      <p className={`py-2 mb-4 logo-style gradient-background-eleven-no-hover text-center 
      text-xl sm:text-2xl shadow-effect-five rounded-xl`}>
        Communities on TipStampers
      </p>
      {/* Map function for arrays */}
      {/* {exampleArray.map((item, i) => {
        return(
              <React.Fragment key={`${i}`}>
                <li>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              </React.Fragment>
              )
            })} */}
      {/* Wrapping div if I want styles, but nothing for now */}
      <div className={``}>
        {/* Online Education */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Online Education
          </div>
          <ul className={`mb-3`}>
            {onlineEducationArray.map((item, i) => {
              return (
                <React.Fragment key={`${i}`}>
                  <li>
                    <a href={`${item.href}`}
                      aria-label={`View ${item.label} Community`}
                      className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                    >
                      {item.label}
                    </a>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
        {/* Writing & Literature */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Writing & Literature
          </div>
          <ul className={`mb-3`}>
            {writingAndLiteratureArray.map((item, i) => {
              return (
                <React.Fragment key={`${i}`}>
                  <li>
                    <a href={`${item.href}`}
                      aria-label={`View ${item.label} Community`}
                      className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                    >
                      {item.label}
                    </a>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
        {/* Technology */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Technology
          </div>
          <ul className={`mb-3`}>
            {technologyArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Social Media */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Social Media
          </div>
          <ul className={`mb-3`}>
            {socialMediaArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Gaming & Esports */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Gaming & eSports
          </div>
          <ul className={`mb-3`}>
            {gamingArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Platform-specific */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Platform-specific Creators
          </div>
          <ul className={`mb-3`}>
            {platformSpecificArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Business & Entrepreneurship */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Business & Entrepreneurship
          </div>
          <ul className={`mb-3`}>
            {businessAndEntreArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Performance Arts
          </div>
          <ul className={`mb-3`}>
            {performanceArtsArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Visual Arts
          </div>
          <ul className={`mb-3`}>
            {visualArtsArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Health, Wellness, Fitness
          </div>
          <ul className={`mb-3`}>
            {healthAndWellnessArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Food & Cooking
          </div>
          <ul className={`mb-3`}>
            {foodAndCookingArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Animals
          </div>
          <ul className={`mb-3`}>
            {animalsArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        {/* Bitcoin-SV */}
        <div className={`mb-3`}>
          <div className={`underline text-underline-under text-lg logo-style mb-3`}>
            Bitcoin (SV)
          </div>
          <ul className={`mb-3`}>
            {bitcoinSVArray.map((item, i) => {
              return (
                <React.Fragment key={`${i}`}>
                  <li>
                    <a href={`${item.href}`}
                      aria-label={`View ${item.label} Community`}
                      className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                    >
                      {item.label}
                    </a>
                  </li>
                </React.Fragment>
              )
            })}
          </ul>
        </div>
        <div className={`mb-3`}>
          <div className={`underline text-underline-under logo-style text-lg mb-3`}>
            Miscellaneous
          </div>
          <ul className={`mb-3`}>
            {miscArray.map((item, i) => {
              return (
                <li key={`${i}`}>
                  <a href={`${item.href}`}
                    aria-label={`View ${item.label} Community`}
                    className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
                  >
                    {item.label}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* Sample */}
      {/* <p className={`mb-3`}>
        <div className={`underline text-underline-under logo-style text-lg mb-3`}>
          Bitcoin (SV)
        </div>
        <ul className={`mb-3`}>
          <li>
            <a href='https://tipstampers.com/posts/bitcoin-story-sharers-on-tipstampers'
              aria-label={`View Community`}
              className={`fifth-hover hover:underline text-underline-under is-link-no-underline`}
            >
              Bitcoin Story Sharers on TipStampers
            </a>
          </li>
        </ul>
      </p> */}
    </>
  )
}

export const MembershipPageNotLoggedInText = () => {
  return (
    <>
      <p className={`mb-2`}>What does TipStampers lifetime membership unlock?</p>
      <ul className='mb-2'>
        <li className='text-sm'>
          FREE posts
        </li>
        <li className='text-sm'>
          $.01 comments
        </li>
        <li className='text-sm'>
          Image Uploading
        </li>
        <li className='text-sm'>
          Added to Mass Tip list
        </li>
        <li className='text-sm'>
          and more.
        </li>
      </ul>
      <p className={`mb-2 underline text-underline-under`}>The <span className='font-bold'>current</span> 1-year price is $<span className={`font-bold text-brand-green`}>{globals.oneYearMembershipPrice.toFixed(2)}</span>.</p>
      <p className={`mb-2 underline text-underline-under`}>The <span className='font-bold'>current</span> Lifetime price is $<span className={`font-bold text-brand-green`}>{globals.lifeTimeMembershipPrice.toFixed(2)}</span>.</p>

      <p className={`mb-2`}>If you are interested:</p>
      <ol className={`mb-2`}>
        <li className='text-sm'>
          Sign-up or log into your HandCash account
        </li>
        <li className='text-sm'>
          Come back to this page
        </li>
        <li className='text-sm'>
          Tap the "Purchase Membership" button
        </li>
        <li className='text-sm'>
          Confirm you would like to become a member, and Presto! You will become one just like that. Simple.
        </li>
      </ol>
      <p className={`mb-2`}>Thanks for visiting our community. We look forward to your contributions!</p>
    </>
  )
}
export const MembershipPageLoggedInAndMember = () => {
  return (
    <>
      <p className={`mb-2`}>You are a certified member of TipStampers.com. Thank you very much!</p>
      <p className={`mb-2 underline text-underline-under`}>To reiterate the benefits from your membership purchase:</p>
      <ul className='mb-2'>
        <li className='text-sm'>
          FREE posts
        </li>
        <li className='text-sm'>
          $.01 comments
        </li>
        <li className='text-sm'>
          Image Uploading
        </li>
        <li className='text-sm'>
          Added to Mass Tip list
        </li>
        <li className='text-sm'>
          and more.
        </li>
      </ul>
      <p className={`mb-2`}>Thanks again for being a member of our community.</p>
    </>
  )
}
export const MembershipPageLoggedInAndNotMember = () => {
  return (
    <>
      <p className={`mb-2`}>What does TipStampers lifetime membership unlock?</p>
      <ul className='mb-2'>
        <li className='text-sm'>
          FREE posts
        </li>
        <li className='text-sm'>
          $.01 comments
        </li>
        <li className='text-sm'>
          Image Uploading
        </li>
        <li className='text-sm'>
          Added to Mass Tip list
        </li>
        <li className='text-sm'>
          and more.
        </li>
      </ul>
      <p className={`mb-2`}>If you are interested:</p>
      <ul className={`mb-2`}>
        <li className='text-sm'>
          Click the “Purchase Membership” button below
        </li>
        <li className='text-sm'>
          Confirm your decision
        </li>
        <li className='text-sm'>
          And you'll become a member as fast the Blockchain processes your transaction (very very quickly).
        </li>
      </ul>
      <p className={`mb-3`}>Thank you for being a part of TipStampers, and thank you for your contributions!</p>
    </>
  )
}
export const MainPageContent = (props) => {
  return (
    <>
      <p className={`mb-3`}>
        Welcome to {globals.platformName}, a "Branded Digital Content Publishing Platform" built on Bitcoin-SV (BSV),
        where you get paid instantly and directly by your audience,
        via the exceptional <a href='https://market.handcash.io/'
          aria-label={`Go to HandCash website`}
          className={`fifth-hover hover:underline`}
          rel="noopener noreferrer"
          target="_blank"
        >HandCash account and wallet system.</a>
      </p>
      <p className={`mb-3`}>
        To paint you a picture,
        we combine typical social media interactions with Blockchain payments to improve the content creation-to-consumption lifecycle and experience.
        Let us explain.
      </p>
      <p className={`mb-3`}>
        By tying these interactions with Blockchain payments, <b className={`mr-1`}>you receive 100% of everything you earn on our platform.</b>
        Every follow (<span className={`font-bold fifth-text`}>${globals.followingCost.toFixed(2)}</span>),
        comment (<span className={`font-bold fifth-text`}>${globals.commentCost.toFixed(2)}</span>),
        thumbs-up (<span className={`font-bold fifth-text`}>${globals.thumbsCostMin.toFixed(2)} - {globals.thumbsCostMax.toFixed(2)}</span>),
        share (<span className={`font-bold fifth-text`}>${globals.shareCost.toFixed(2)}</span>),
        tip (<span className={`font-bold fifth-text`}>set by others</span>),
        and paywall purchase (<span className={`font-bold fifth-text`}>set by you</span>) goes directly to your HandCash wallet,
        without the platform taking a cut. You just have to pay our small, spam-filtering “troll-toll” to post something,
        and every cent thereafter goes to you. 100%.
      </p>
      <p className={`mb-3`}>
        But you don’t need to have any BSV to get started.
        After creating and logging in with your HandCash account,
        simply introduce yourself in the FREE “Meet The Stampers" category
        and our friendly community will help get you going.
        Alternatively, BSV can be purchased through HandCash as well.
      </p>
      <p className={`mb-3`}>
        Click around, explore, and if you like what you see, give us a shot. Thanks for checking us out.
      </p>
    </>
  );
};
const DescriptionSetup = (props) => {
  const { topicText, categoryCost } = props;
  return (
    <>
      <hr className={`sixth-border outsetBorder border mb-3 rounded`}></hr>
      <div className={`font-header text-sm first-text`}>
        <span className={`font-bold`}>Topics</span>:<span className={`ml-1 text-xs`}>{topicText}</span>
        <div className=''>
          <div className={`flex items-baseline`}>
            <div className={`mr-1 font-bold`}>
              Price to Earn:
            </div>
            <div className={`flex items-center text-xs`}>
              <div className={`font-bold text-brand-green mr-1`}>{categoryCost}</div>
              <div className={``}><Icon icon={bitcoinsvIcon} height={12} width={12} /></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
// Complete (not 100% happy with it, but good enough)
export const AllDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        If you're not sure what you're looking for, you're probably in the right place.
      </p>
      <p className={`mb-3`}>
        In this "category" you'll find content from all of our categories,
        sorted however you like. Enjoy this hodge podge of content.
      </p>
      <DescriptionSetup
        topicText={`Everything on the platform`}
        categoryCost={`Varies by category`}
      />
    </>
  )
}
export const StampYouDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        This category is about YOU. This is YOUR channel. YOUR blog. YOUR canvas. YOUR stamps.
      </p>
      <p className={`mb-3`}>
        Express yourself via Blog, Vlog, Stream, Podcast, Audio, Video, Text, you name it.
        The medium doesn’t matter, and whether you create your content here or elsewhere, doesn’t matter either.
        Within your posts, you may embed TikToks, Instagram Reels, Spotify Audio, Apple Podcasts, YouTube videos and more.
        All while earning 100% cut from your creative efforts.
      </p>
      <p className={`mb-3`}>
        Create a
        <a href="https://market.handcash.io/"
          className={`underline text-underline-under ml-1`}
          aria-label={`Go to the HandCash website to create an account if you do not have one.`}
          target="_blank"
          rel="noopener noreferrer">
          HandCash account</a> to start stamping and earning today.
      </p>
      <p className={`mb-3`}>
        Welcome to TipStampers.com, where creators come first, 100% of the time.
      </p>
      <DescriptionSetup
        topicText={`The choice is yours. This is YOUR channel.`}
        categoryCost={`$${globals.stampYou.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const CommunitiesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        <span className={`ninth-text font-bold underline text-underline-under`}>
          Creators:</span> Introduce yourself & share a sample of your content
      </p>
      <p className={`mb-3`}>
        <span className={`ninth-text font-bold underline text-underline-under`}>
          Everyone:</span> Meet your creators, engage, and explore their content
      </p>
      <DescriptionSetup
        topicText={`A list of our Creator Communities`}
        categoryCost={`$${globals.communities.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const WritingGamesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Welcome to the <span className={`ninth-text font-bold`}>
          TipStampers Writing Games</span>.
      </p>
      <p className={`mb-3`}>
        The games consist of us posing a topic for you to write about, and you creating the best reply you can. Preferably authentic and without AI, except for fine-tuning.
      </p>
      <p className={`mb-3`}>
        Rewards are given to our favorite responses, and the community can tip you as well.
      </p>
      <p className={`mb-3`}>
        Good luck, and let the games begin.
      </p>
    </>
  )
}
export const PromptGamesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Welcome to the <span className={`ninth-text font-bold`}>
          TipStampers Prompt Games</span>.
      </p>
      <p className={`mb-3`}>
        The games consist of us posing a topic for you to talk about, and you creating the best reply you can. Preferably authentic and without AI, except for fine-tuning.      </p>
      <p className={`mb-3`}>
        Rewards are given to our favorite responses, and the community can tip you as well.
      </p>
      <p className={`mb-3`}>
        Good luck, have fun, and let the games begin.
      </p>
    </>
  )
}
export const MeetTheStampersDescription = () => {
  return (
    <>
      <p className={``}>
        <span className='underline text-underline-under'>Stamper</span>: Meet our community.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Community</span>: Meet our latest Stamper, and shower them with tips to start their earning journey.
      </p>
      <p className={`mb-3`}>
        If you’re a new Stamper with an empty HandCash account, introduce yourself here for free.
        Share your business, Social Media accounts, something interesting & potentially tipworthy, or
        optionally hide content behind a paywall and entice other Stampers to purchase it.
        Then sit back and let the community do its tipping thing.
      </p>
      <p className={`mb-3`}>
        This is our *FREE* introductory category and you only get one shot at it, so do your best to make it count.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Introductions, Tipworthy Content, Links, Twitter & SM Handles, Misc`}
        categoryCost={`FREE`}
      />
    </>
  )
}
export const PanoramicReelsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Let's be Reel now. Panoramic pictures are the past, Panoramic videos are the future.
      </p>
      <p className={`mb-3`}>
        In this category, add Panoramic Instagram Reels into your posts, and let your audience decide if they are tipworthy.
        Going further, add enticement in the free section, then hide your Panoramics behind the paywall. Hopefully you'll find it lucrative.
      </p>
      <p className={`mb-3`}>
        Mesmerize others with circular beauty before your eyes.
      </p>
      <DescriptionSetup
        topicText={`Tipworthy Panoramics. Nature, Special Occasions, etc.`}
        categoryCost={`$${globals.panoramicReels.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const TikTokPlaylistDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        What's on your TikTok Playlist?
      </p>
      <p className={`mb-3`}>
        Share yours or other creator's TikToks that might interest others here, with the more tipworthy the content, the better.
        Like "Stamp You" this is a hodge-podge category so all #TipToks are welcome here. (<i>Well, almost all of them...</i>)
      </p>
      <p className={`mb-3`}>
        Viewers: If you enjoyed the #TipTok, be sure to send the creator a tip. That's the name of the game here, and thanks for playing.
      </p>
      <DescriptionSetup
        topicText={`Any and all interesting #TipToks. Hodge-podge.`}
        categoryCost={`$${globals.tiktokPlaylist.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const TeachDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Got something to teach that doesn’t fall under the “How To” umbrella? Have expertise on a particular subject?
        If so, spread your knowledge within this informative category.
      </p>
      <p className={``}>
        <span className='underline text-underline-under'>Teachers:</span> As long as your content is lawful and appropriate, teach whatever you like.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Learners:</span> If you learned something new, send your teacher a tip.
      </p>
      <p className={`mb-3`}>
        Teach and learn till the end of time.
      </p>
      <DescriptionSetup
        topicText={`Any subject you have knowledge in`}
        categoryCost={`$${globals.teach.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const CookingDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Foodies and cooks of the world, this one's for you.
      </p>
      <p className={`mb-3`}>
        Share your recipes, cook on camera, give advice, and make our mouth's water.
      </p>
      <p className={`mb-3`}>
        The deal is if the cooking content was tipworthy tasty, you send your cook a tip.
        Bypass the server and send a thumbs-up directly to them. Hopefully they won't put it in your soup.
      </p>
      <p className={`mb-3`}>
        Bon Appétit.
      </p>
      <DescriptionSetup
        topicText={`Recipes & Videos`}
        categoryCost={`$${globals.cooking.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const FitnessDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        The TipStampers <i className='mr-1'>Buff 'n Tuff n' Stuff</i> fitness arena is open and ready for your participation.
        In this category, the heat is turned up and you'll be certain to feel the burn.
      </p>
      <p className={`mb-3`}>
        Spread or enhance your knowledge through tutorials, write down quality workouts, demonstrate proper form, and so on.
        Exercise is the natural health and mood booster, so tip generously to those who help give you that boost.
      </p>
      <p className={`mb-3`}>
        Create away, fitness gurus of the world.
      </p>
      <DescriptionSetup
        topicText={`Workouts, Demonstrations, Education, etc.`}
        categoryCost={`$${globals.fitness.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const GamingDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        eSports ain’t no joke. Paying homage to this highly entertaining and fast-growing industry,
        this category is for all the gaming enthusiasts out there.
        Twitch streamers, passive gamers, fans, promoters, it doesn’t matter. Everyone can join our tip gaming fun.
      </p>
      <p className={`mb-3`}>
        Share yours or other gamers Twitch & YouTube videos, promote or recap a tournament, partake in balance discussion,
        chat about players, share links, reminisce about old N64 games, the choice is yours.
        If it’s gaming related, it flies. But bad manners do not. GG’s are expected.
      </p>
      <p className={`mb-3`}>
        Now gl hf.
      </p>
      <DescriptionSetup
        topicText={`Videos, Links, Games, Players, Events, Discussions, Haste Arcade, Duro Dogs, CryptoFights`}
        categoryCost={`$${globals.gaming.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
// Complete (not 100% happy with it, but good enough)
export const TalentShowcaseDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        The name of the game is to entertain, so if you’ve got a tipworthy talent to show off,
        share it with us here.
      </p>
      <p className={`mb-3`}>
        Tiktoks, YouTube, Vimeo, Twitch videos, Facebook Watch, & Instagram Reels are all available options to include within your post.
      </p>
      <p className={`mb-3`}>
        Amaze away, talent showcasers of the world.
      </p>
      <DescriptionSetup
        topicText={`Links & Vids. Yours or recommendations of others.`}
        categoryCost={`$${globals.talentShowcase.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const ComedyDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        It's tough to beat a live show at Comedy Corner Underground, but no matter the medium
        there's nothing like a good laugh, so if you’ve got the comedic chops, do take our center stage.
      </p>
      <p className={`mb-3`}>
        Stand-ups, skits, jokes, and comedic writings are all welcome here,
        along with anything else that might produce some lingering laughs.
        And if you’re not a funny one yourself, feel free to share some favorites from others too.
      </p>
      <p className={`mb-3`}>
        Now let’s stamp some laughs and tip those who produce them.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Stand-ups, skits, jokes, comedic writings, etc.`}
        categoryCost={`$${globals.comedy.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const BeautifulNatureDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        People can’t agree on most things, but if there was any one thing we could,
        it would have to be our appreciation for nature’s marvels.
      </p>
      <p className={`mb-3`}>
        When we lay our eyes on something magnificent or beautiful from nature, we can’t help but feel joy. It consumes us.
      </p>
      <p className={`mb-3`}>
        So if you have quality videos from Tiktok & Instagram, or you’ve taken a collage of beautiful pictures from nature,
        give this gift to others by stamping them here.
        Share your experience while you gathered them too.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Note 1</span>: We aim to keep this “nature’s only”
        so do your best to keep mankind out of the picture - no matter how lovely the person is.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Note 2</span>: Only Members can upload pictures, so become a Member today so you can too!
      </p>
      <DescriptionSetup
        topicText={`Nature Pics and Collages`}
        categoryCost={`$${globals.beautifulNature.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const ArtDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        You don't need to be DaVinci to share your artistic handiwork here. All forms of art qualify, with the more tipworthy the better.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Gallery Viewers</span>: If your eyes appreciate what you see, be sure to send your starving artist a tip. They deserve it.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Note</span>: Only Members can upload pictures, so become a Member today so you can too!
      </p>
      <DescriptionSetup
        topicText={`All forms`}
        categoryCost={`$${globals.art.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const AnimalsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        There's something about those furry creatures that gives us great joy, and we aim to capture that here.
      </p>
      <p className={`mb-3`}>
        Share any funny or cute animal #TipToks you have, or anything else including animals that would be deemed tipworthy.
      </p>
      <p className={`mb-3`}>
        Happy Pet Stamping.
      </p>
      <DescriptionSetup
        topicText={`Funny, Cute, etc.`}
        categoryCost={`$${globals.animals.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const SportsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Sports can be epic and when those epic moments are caught on video, we hope you stamp them here.
      </p>
      <p className={`mb-3`}>
        In this #TipTok category, it doesn't matter what sport is played, as long as the content is tipworthy, we recommend you sharing it here.
      </p>
      <p className={`mb-3`}>
        <span className='underline text-underline-under'>Viewers</span>: If the content hit a home run, tally a win for the home team by sending them a tip.
      </p>
      <p className={`mb-3`}>
        Good luck and may the best player win.
      </p>
      <DescriptionSetup
        topicText={`Clips, Discussions, etc.`}
        categoryCost={`$${globals.sports.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const MusicDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Welcome to the TipStampers.com beat laboratory. YOUR beat laboratory.
      </p>
      <p className={`mb-3`}>
        In this category you have free-reign to drop your latest tunes, post musical performances,
        share music videos, discuss lyrics, and add anything your musical mind desires,
        so long as it goes to the rhythm of the beat. Spotify, SoundCloud, and YouTube are all available embedding options here.
      </p>
      <p className={`mb-3`}>
        Music is medicine for the soul, and we look forward to having a dose of yours.
      </p>
      <DescriptionSetup
        topicText={`Tracks, Performances, Music Videos, Lyrics, etc.`}
        categoryCost={`$${globals.music.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
// Professional
// Complete
export const EntrepreneurshipDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Entrepreneurship is fundamental to advancing our society,
        and we would like to help those that pursue – or are interested in pursuing – this challenging undertaking.
      </p>
      <p className={`mb-3`}>
        Thus, we encourage you to post content or questions related to the subject,
        which may include any the following:
      </p>
      <ul className={`mb-3`}>
        <li>Entrepreneurship stories, pieces of advice, lessons learned, etc.</li>
        <li>Questions from the community (and possibly venting)</li>
        <li>Book recommendations</li>
        <li>Outside resources that are helpful to others</li>
        <li>Anything else that pertains to it</li>
      </ul>
      <p className={`mb-3`}>This one’s for you, entrepreneur hopefuls of the world.</p>
      <DescriptionSetup
        topicText={`Experiences, Books, Resources, Links, Recommendations, Help`}
        categoryCost={`$${globals.entrepreneurship.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
// Complete
export const CareerDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        “Wisdom in the workplace” would also be a fitting title for this category,
        as we encourage you to share bits of wisdom from the office here.
      </p>
      <p className={`mb-3`}>
        If you have advice, specialized or generalized knowledge, interesting lessons, experiences to tell,
        top-notch principles to follow, or anything valuable to others within this realm,
        don’t hesitate to share.
      </p>
      <p className={`mb-3`}>
        And don’t hesitate to ask for advice, either.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Advice, Knowledge, Lessons, Experiences, Principles, etc.`}
        categoryCost={`$${globals.career.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const PromotionsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Need to pitch something to our TipStamping community? Want them to check out a new product or service? Here's where you should do it.
      </p>
      <p className={`mb-3`}>
        As long as you're not promoting something obviously inappropriate, your advertisement is welcome here.
        Hope your business gets a boost!
      </p>
      <DescriptionSetup
        topicText={`Your Business, Special Offers, Products, etc.`}
        categoryCost={`$${globals.promotions.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const InTheNewsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        You’ve come to the right place if you’re looking to view, read about, and discuss the world’s hot topics of the day.
        Ranging from anything, to everything. (Well, maybe not everything…)
      </p>
      <p className={`mb-3`}>
        In this category, we encourage sharing article links, discussing viewpoints,
        or simply stamping your opinion on a subject. Naturally, we anticipate debate and disagreement,
        so please remember that you can’t change everyone’s mind no matter how hard you try.
        And there IS an emotion-filled human on the other end of the screen, just like YOU.
      </p>
      <p className={`mb-3`}>
        So keep things classy and respectful, and don’t go internet keyboard warrioring on us now. Professionalism is expected.
      </p>
      <DescriptionSetup
        topicText={`The happenings in our world`}
        categoryCost={`$${globals.inTheNews.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const InterviewsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        It's easy to appreciate a good interview from people with interesting perspectives, experiences, and knowledge.
        So if you come across a good one or you've been interviewed yourself, share it with us here.
      </p>
      <p className={`mb-3`}>
        Thank you for your contributions.
      </p>
      <DescriptionSetup
        topicText={`Entrepeneurship, Professional, Stories, Experiences, Events, Links`}
        categoryCost={`$${globals.interviews.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const AMADescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Everyone enjoys a good AMA (Ask Me Anything)
        session from people with interesting perspectives, experiences, and knowledge.
        We sure do here, so if you think you’re a good candidate for one,
        don’t think twice about engaging in this classic online exchange.
      </p>
      <p className={`mb-3`}>
        Just introduce yourself along with the necessary details to draw your audience’s interest, questions, and tips.
        We definitely encourage business-related AMA’s, but there’s absolutely no topic requirement,
        so if you’ve got something interesting to share, please share away.
        Also acceptable is sharing links to other AMA's or interviews.
        They don't have to come strictly from here.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Entrepeneurship, Professional, Stories, Experiences, Events, Links`}
        categoryCost={`$${globals.ama.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const BitcoinBusinessesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Promote your Bitcoin Business here.
      </p>
      <DescriptionSetup
        topicText={`Promote your Bitcoin Business here`}
        categoryCost={`$${globals.bitcoinBusinesses.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
// Complete
export const BSVNewsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>In this category, you’ll find the latest developments within the Bitcoin-SV ecosystem, ranging from…</p>
      <ul className={`mb-3`}>
        <li>Product launches and communicating with the community</li>
        <li>Industry developments</li>
        <li>Promotion of BSV apps or businesses</li>
        <li>Promotion of non-BSV apps or businesses</li>
        <li>Not drama</li>
        <li>And more </li>
      </ul>
      <p className={`mb-3`}>
        It’s all about the business and we look forward to hearing about yours. Even if it's not on BSV.
      </p>
      <DescriptionSetup
        topicText={`Apps, Business, Developments, Tech`}
        categoryCost={`$${globals.appsAndBusinessNews.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
// Knowledge
export const TutorialsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Who deserves to be voluntarily tipped more than someone who teaches you how to do something? Nobody, that’s who.
        No one deserves a reward more than our teachers of the world, so please be generous to these important individuals.
      </p>
      <p className={`mb-3`}>
        Within this category, spread your knowledge and post any How To Tutorials you’ve created,
        and hopefully you are rewarded as you deserve to be. Besides something inappropriate,
        you may share tutorials on any subject you like.
      </p>
      <p className={`mb-3`}>
        We wish you luck, and thank you for creating content that benefits people’s lives. Kudos to you.
      </p>
      <DescriptionSetup
        topicText={`Anything beneficial towards people's lives`}
        categoryCost={`$${globals.tutorials.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
// Complete
export const ArticlesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        To earn as much from your article as possible, we recommend its quality be on the level of the Wall Street Journal,
        but in no way do we expect that. Inexperienced writers to seasoned vets, are ALL welcomed here.
        We just ask you to give your best and present yourself and the article in a professional manner.
      </p>
      <p className={`mb-3`}>
        When it comes to topics, writings are a reflection of yourself, and you can reflect whatever you like.
        Write something political, about Blockchain, life, business, or something you studied.
        It makes no difference to us. You may even share other articles from elsewhere and discuss the contents within it as well.
        We just ask you conduct yourself professionally. We’re not looking to moderate or be another Twitter here.
      </p>
      <p className={`mb-3`}>
        Happy earning, and thank you in advance.
      </p>
      <DescriptionSetup
        topicText={`Any. Just conduct yourself in a professional manner.`}
        categoryCost={`$${globals.articles.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const ChatGPTDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        ChatGPT has taken the world by storm, and thus, we've created this category for people to create and find content related to this fascinating tool.
      </p>
      <p className={`mb-3`}>
        Share your screenshots, demonstrate how to use it, and teach others with it. Debate is also encouraged, as we know this subject is controversial in nature.
        We would love to hear your input.
      </p>
      <p className={`mb-3`}>
        Happy Prompting.
      </p>
      <DescriptionSetup
        topicText={`Screenshots, Teaching, Articles, etc.`}
        categoryCost={`$${globals.chatGPT.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const ScienceDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Welcome to our Science Hub, where curiosity meets discovery!
        Dive into the fascinating realm of scientific wonders and share your passion for the extraordinary.
      </p>
      <p className={`mb-3`}>
        Whether you're into physics, biology, astronomy, or any other scientific discipline,
        this is your go-to destination for sharing cool science-related topics and sparking thought-provoking conversations.
        Let the scientific adventure begin!
      </p>
      <DescriptionSetup
        topicText={`Fascinating Science, Articles, etc.`}
        categoryCost={`$${globals.chatGPT.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const LifeDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Whether you’re in a pickle and need advice, or you have some to share, this category is for you.
      </p>
      <p className={`mb-3`}>
        We want to improve people’s lives here at TipStampers in any way possible,
        and if you feel like you can help, don’t keep all your tricks up your sleeve.
        Slide them down and share them with the world, for all to see.
      </p>
      <p className={`mb-3`}>
        We thank you for any contributions in advance.
      </p>
      <DescriptionSetup
        topicText={`Advice, Lessons, Habits, etc.`}
        categoryCost={`$${globals.life.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const ReviewsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Review whatever you like: Restaurants, Products, Movies, Books, etc.
      </p>
      <p className={`mb-3`}>
        Be aware that your review will be stamped to the Blockchain,
        so do your best to be fair and provide constructive criticism when warranted. No need to get nasty here.
      </p>
      <p className={`mb-3`}>
        Thank you for your efforts.
      </p>
      <DescriptionSetup
        topicText={`Restaurants, Products, Movies, Books, etc.`}
        categoryCost={`$${globals.life.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const HealthDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        <i>“You ain’t got nothin’ if you ain’t got your health”</i> is a quote we should never ignore.
        Nor should we ignore the knowledgeable creators who publish content that helps us retain or improve it.
      </p>
      <p className={`mb-3`}>
        In this category, as long as the content is geared towards retaining or improving our health, you're on target.
      </p>
      <p className={`mb-3`}>
        Happy healing.
      </p>
      <DescriptionSetup
        topicText={`Diet, Advice, etc.`}
        categoryCost={`$${globals.health.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
// Complete
export const CodeShareDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Coding is a primary interest within the TipStamping community,
        and our primary interest is to fill this category with coding snippets and demos that can help people develop on Bitcoin-SV.
      </p>
      <p className={`mb-3`}>
        As the name implies, you can share Codepen-like snippets, YouTube tutorials or Instagram Reels,
        but you’re not limited to just that. Just like you’re not limited to sharing only BSV-based code.
      </p>
      <p className={`mb-3`}>
        If you would like to share a useful snippet of something cool in JavaScript, you are more than welcome to do so.
        If you would like to use this as a Stackoverflow Q & A, you are also welcome to do so.
        If you would like to write blog-type posts where you share experiences or something you learned, don't hesitate for a second.
        As long as it is code based, it’s not a waste.
      </p>
      <DescriptionSetup
        topicText={`YouTube Vids, Tutorials, Snippets, Reels, Articles, Links`}
        categoryCost={`$${globals.codeShare.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const BooksDescription = (props) => {
  return (
    <>
      <p className={`mb-3`}>
        Books. The embodiment of knowledge, and a category a platform like this had to have.
      </p>
      <p className={`mb-3`}>
        Make recommendations, give reviews, share what you’ve learned,
        ask for suggestions, create lists, or start discussions - the choice is yours.
      </p>
      <DescriptionSetup
        topicText={`Recommendations, Reviews, Learned, Suggestions, Lists, Discussions, etc.`}
        categoryCost={`$${globals.books.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}

// Bonus Category
export const BitcoinStoriesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Share your Bitcoin Stories here! Include details such as:
      </p>
      <ul>
        <li className={``}>
          How you were first introduced to Bitcoin
        </li>
        <li className={``}>
          What intrigued you about it

        </li>
        <li className={``}>
          Things you've learned since
        </li>
        <li className={`mb-3`}>
          And where you see it going in the future
        </li>
      </ul>
      <p className={`mb-3`}>
        Bonus point for sharing how you came across TipStampers as well.
      </p>
      <DescriptionSetup
        topicText={`YOUR Bitcoin Stories. Partials or Full`}
        categoryCost={`$${globals.bitcoinStories.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const PollsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        The Tipstampers polling station is open and we need your vote. We also need new polls for people to vote on, so if
        you've got a good idea for one, stamp it to our platform. Each vote from your poll will be $.02 in your pocket.
      </p>
      <p className={`mb-3`}>
        If you're a TipStampers Member, you may also add a "Stampshot" to your poll, which within 48 hours,
        will freeze the poll and stamp the results to the Blockchain, for the world to forever see.
      </p>
      <p className={`mb-3`}>
        Have fun and poll whatever you like, so long as it’s appropriate.
      </p>
      <DescriptionSetup
        topicText={`Any and All. Just keep it classy`}
        categoryCost={`$${globals.polls.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const ListsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        <span className={`text-brand-green font-bold`}>To stamp your list to the Blockchain:</span> Create a post with a title, write a description, add your searchable tags,
        and include 7 or more entries to start the list - OR - include none at all and let the community form it,
        with the most thumb’ed entries rising to the top.
      </p>
      <ul>
        <li className={`mb-3`}>
          From there, your audience and you can interact.
          They make suggestions, leave comments, and you as the author decide the edits going forward.
        </li>
        <li className={`mb-3`}>
          Topic can be whatever you like, but remember this is public and children can see, so keep it classy. Be professional.
        </li>
        <li className={`mb-3`}>
          But have fun. Movie lists are great, something educational is better, ChatGPT & AI topics are encouraged;
          usefulness and entertaining is the goal.
        </li>
      </ul>
      <p className={`mb-3`}>
        <span className={`text-brand-green font-bold`}>You’re earning money from this after all:
        </span><span className={`underline, text-underline-under`}> 100% cut </span>
        directly from your audience, instantly into your HandCash account, with a Blockchain #TipStamp included.
        So the more #tipworthy your list is, the more you stand to earn. In theory, anyway...
      </p>
      <p className={`mb-3`}>
        Live on Listoka, live on.
      </p>
      <DescriptionSetup
        topicText={`Whatever you like, just keep it classy. Be professional. Have fun.`}
        categoryCost={`$${globals.lists.settings.costToPost.toFixed(2)}`}
      />
      <p className={`font-header text-sm first-text flex items-baseline`}>
        <div className={`mr-1 font-bold`}>
          Price to Stampshot:
        </div>
        <div className={`flex items-center text-xs`}>
          <div className={`font-bold text-brand-green mr-1`}>$.03</div>
          <div className={``}><Icon icon={bitcoinsvIcon} height={12} width={12} /></div>
        </div>
      </p>
    </>
  );
};
export const QuestionsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        You ask and the community answers.
      </p>
      <p className={`mb-3`}>
        In this classic “Question & Answer” category, you can challenge, quiz, theory-craft,
        or simply gather information from other community members.
        We just ask that you keep it professional and appropriate, but the choice is yours when it comes to topic.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Professional, Life, Anything. "Stampshotable"`}
        categoryCost={`$${globals.questions.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const PodcastsDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        We would love to see Joe Rogan post his content here, but you don't have to be him to post yours.
        Everyone has to get their start somewhere.
      </p>
      <p className={`mb-3`}>
        Podcasts from Spotify, Soundcloud, YouTube, and Apple Podcasts can all be embedded within your post. Share your Podcast or comment about others,
        the choice is yours.
      </p>
      <p className={`mb-3`}>
        Enjoy.
      </p>
      <DescriptionSetup
        topicText={`Professional, Entertaining, Informative, etc.`}
        categoryCost={`$${globals.podcasts.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const StoriesDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        It’s storytime, folks. Find your spot on a comfy couch, settle in, and get ready for some mind stimulation.
      </p>
      <p className={`mb-3`}>
        In this category, we aren’t partial to any particular kind of story.
        It can be fiction or nonfiction, or maybe some tricky combination of both.
        Keep us guessing if you like. As long as you’ve got an interesting story to tell, our community is eager to hear it.
      </p>
      <p className={`mb-3`}>
        And here at TipStampers.com, we are also eager to see you earn,
        so don’t hesitate to tease your audience with some free content, then hide the remainder behind a paywall.
        You don’t need to be a big-wig newspaper editor to earn like this anymore.
        At Tipstampers, you get paid on demand, with 100% of the cut going directly to you. Hard to beat that, wouldn't you say?
      </p>
      <p className={`mb-3`}>
        Storytime excellence, incoming.
      </p>
      <DescriptionSetup
        topicText={`Fiction, Nonfiction, Drama, Inspirational, Fantasy, Bitcoin, etc.`}
        categoryCost={`$${globals.stories.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const LetterForeverDescription = () => {
  return (
    <>
      <p className={`mb-3`}>
        Your letter will hit our database, but it will also be permanently stamped on the Blockchain forever.
        Hence, the name. So, keep that in mind when you write it.
      </p>
      <p className={`mb-3`}>
        Write to Elon Musk, a business, your grandma, someone from the past, an old friend, the choice is yours.
        Tug on some heart strings while you're at it.
      </p>
      <p className={`mb-3`}>
        Good luck.
      </p>
      <DescriptionSetup
        topicText={`Businesses, Individuals, Deceased, Thank You's, etc.`}
        categoryCost={`$${globals.letterForever.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
export const PoetryDescription = (props) => {

  return (
    <>
      <p className={`mb-3`}>
        Share your poetic art here.
      </p>
      <p className={`mb-3`}>
        Have fun, enjoy the content.
      </p>
      <DescriptionSetup
        topicText={`Poems, Lyrics, Rythmes, Wordplay, etc.`}
        categoryCost={`$${globals.poetry.settings.costToPost.toFixed(2)}`}
      />
    </>
  )
}
// Bounty challenges
export const PromptsDescription = (props) => {

  return (
    <>
      <p className={`mb-3`}>
        The name of the game here is put up a financial bounty for the completion of a challenge.
        The platform will issue our own bounty challenges from time-to-time, but you're also welcome to post your own.
        Think of it like a micropayment task, job board. Whatever that is.
      </p>
      <p className={`mb-3`}>
        Pay up if the challenge is fulfilled, don't issue the challenge if you don't have the dough.
      </p>
      <p className={`mb-3`}>
        As long as the challenge is appropriate, we won't interfere. Good luck!
      </p>
      <DescriptionSetup
        topicText={`Any`}
        categoryCost={`$${globals.prompts.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
// Bounty challenges
export const BountiesDescription = (props) => {

  return (
    <>
      <p className={`mb-3`}>
        The name of the game here is put up a financial bounty for the completion of a challenge.
        The platform will issue our own bounty challenges from time-to-time, but you're also welcome to post your own.
        Think of it like a micropayment task, job board. Whatever that is.
      </p>
      <p className={`mb-3`}>
        Pay up if the challenge is fulfilled, don't issue the challenge if you don't have the dough.
      </p>
      <p className={`mb-3`}>
        As long as the challenge is appropriate, we won't interfere. Good luck!
      </p>
      <DescriptionSetup
        topicText={`Any`}
        categoryCost={`$${globals.prompts.settings.costToPost.toFixed(2)}`}
      />
    </>
  );
};
export const WaterCoolerDescription = (props) => {

  return (
    <>
      <p className={`mb-3`}>
        Welcome to the exclusive “Water Cooler” where all the cool secrets are spilled. (Just kidding.)
      </p>
      <p className={`mb-3`}>
        But we don’t have to be. You can share secrets or whatever you like with us and other members of the platform here.
        You have absolute free reign,
        as you’ve earned the right for a spot at the cooler with the purchase of your membership.
        So, if you have questions, suggestions, or confessions, we’re more than happy to hear them out here at the Water Cooler.
      </p>
      <p className={`mb-3`}>
        Thanks again for being an integral part of the community.
      </p>
      <DescriptionSetup
        topicText={`Questions, Suggestions, or whatever your heart desires`}
        categoryCost={`FREE`}
      />
    </>
  )
}