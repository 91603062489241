import React from 'react';
import {
  Icon,
  stampIcon,
  handshakeIcon,
  vote20Regular,
  bulletListLine,
  codeLine,
  textDocumentEdit,
  musicInCollection,
  openBook,
  food16Filled,
  educationIcon,
  laughSquint,
  flowerIcon,
  twitchIcon,
  catIcon,
  myMoviesTV,
  brandTiktok,
  colorPaletteSolid,
  barbellIcon,
  basketballIcon,
  heartbeatIcon,
  bookSolid,
  personQuestionMark20Filled,
  knowledgeArticle,
  smileyO,
  helpInfoSolid,
  currencyUsd,
  podcastIcon,
  clipboardCheck,
  brainCircuit20Filled,
  instagramIcon,
  diplomaScroll,
  atomSolid,
  bitcoinsvIcon,
  // ---- Professional category ----
  briefcaseIcon,
  newspaperIcon,
  microphoneSolid,
  talkBubblesLine,
  lightbulbIcon,
  applicationBraces,
  adIcon
} from '../../config/icons';

const SelectCategoryRadioButton = (props) => {
  return (
    <>
      <div className=''>
        <input
          type="radio"
          id={props.value}
          name='selectCategory'
          className={`mb-0 hidden`}
          value={props.value}
          onChange={(e) => props.handleChange(e)}
        />
        <label
          className={`items-center flex justify-center m-1 category-font-size cursor-pointer rounded-xl`}
          htmlFor={props.value}>
            {props.display}
            {props.display === 'Prompt Games' ? <div className='ml-1'><Icon icon={textDocumentEdit} height={16} width={16} /></div> : <></>}
            {props.display === 'Stamp You' ? <div className={`ml-2px -mt-2px`}><Icon icon={stampIcon} height={19} width={19} /></div> : <></>}
            {props.display === 'Meet the Stampers' ? <div className={`ml-1 mt-px`}><Icon icon={handshakeIcon} height={19} width={19} /></div> : <></>}
            {/* Entertainment + */}
            {props.display === 'Twitch Gaming' ? <div className='ml-1'><Icon icon={twitchIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Spotify Soundcloud' ? <div className='ml-1'><Icon icon={musicInCollection} height={16} width={16} /></div> : <></>}
            {props.display === 'Podcasts' ? <div className='ml-1'><Icon icon={podcastIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Teach & Learn' ? <div className='ml-1'><Icon icon={educationIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Comedy Corner' ? <div className='ml-1'><Icon icon={laughSquint} height={16} width={16} /></div> : <></>}
            {props.display === 'YouTube Sports' ? <div className='ml-1'><Icon icon={basketballIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Insta Fitness' ? <div className='ml-1'><Icon icon={barbellIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Cooking' ? <div className='ml-1'><Icon icon={food16Filled} height={16} width={16} /></div> : <></>}
            {props.display === 'Talent Showcase' ? <div className='ml-1'><Icon icon={myMoviesTV} height={16} width={16} /></div> : <></>}
            {props.display === 'Animal Love' ? <div className='ml-1'><Icon icon={catIcon} height={20} width={20} /></div> : <></>}
            {props.display === 'Art' ? <div className='ml-1'><Icon icon={colorPaletteSolid} height={20} width={20} /></div> : <></>}
            {props.display === 'Beautiful Nature' ? <div className='ml-1'><Icon icon={flowerIcon} height={16} width={16} /></div> : <></>}
            {props.display === 'TikTok Playlist' ? <div className='ml-1'><Icon icon={brandTiktok} height={20} width={20} /></div> : <></>}
            {props.display === 'Panoramic Reels' ? <div className='ml-2px'><Icon icon={instagramIcon} height={20} width={20} /></div> : <></>}
            {/* Professional */}
            {props.display === 'Entrepreneurship' ? <div className='ml-1'><Icon icon={lightbulbIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'AMA' ? <div className='ml-1 mb-2px'><Icon icon={talkBubblesLine} height={18} width={18} /></div> : <></>}
            {props.display === 'Business & Career' ? <div className='ml-1'><Icon icon={briefcaseIcon} height={16} width={16} /></div> : <></>}
            {props.display === 'Interviews' ? <div className='ml-1'><Icon icon={microphoneSolid} height={18} width={18} /></div> : <></>}
            {props.display === 'In The News' ? <div className='ml-1'><Icon icon={newspaperIcon} height={16} width={16} /></div> : <></>}
            {props.display === 'Bitcoin Businesses' ? <div className='ml-1'><Icon icon={applicationBraces} height={18} width={18} /></div> : <></>}
            {props.display === 'Branded Promotions' ? <div className='ml-1'><Icon icon={adIcon} height={18} width={18} /></div> : <></>}
            {/* Knowledge */}
            {props.display === 'How To Tutorials' ? <div className='ml-px'><Icon icon={helpInfoSolid} height={18} width={18} /></div> : <></>}
            {props.display === 'Articles' ? <div className='ml-1'><Icon icon={knowledgeArticle} height={18} width={18} /></div> : <></>}
            {props.display === 'ChatGPT & AI' ? <div className='ml-1'><Icon icon={brainCircuit20Filled} height={20} width={20} /></div> : <></>}
            {props.display === 'Science' ? <div className='ml-1'><Icon icon={atomSolid} height={20} width={20} /></div> : <></>}
            {props.display === 'Reviews' ? <div className='ml-1'><Icon icon={clipboardCheck} height={20} width={20} /></div> : <></>}
            {props.display === 'Life' ? <div className='ml-1'><Icon icon={smileyO} height={18} width={18} /></div> : <></>}
            {props.display === 'Health & Diet' ? <div className='ml-1'><Icon icon={heartbeatIcon} height={18} width={18} /></div> : <></>}
            {props.display === 'Code Share' ? <div className='ml-1'><Icon icon={codeLine} height={20} width={20} /></div> : <></>}
            {props.display === 'Books' ? <div className='ml-1'><Icon icon={bookSolid} height={18} width={18} /></div> : <></>}
            {/* Bonus */}
            {props.display === 'Polls' ? <div className='ml-1 mb-1'><Icon icon={vote20Regular} height={16} width={16} /></div> : <></>}
            {props.display === 'Lists' ? <div className='ml-1'><Icon icon={bulletListLine} height={18} width={18} /></div> : <></>}
            {props.display === 'Questions' ? <div className='ml-1'><Icon icon={personQuestionMark20Filled} height={18} width={18} /></div> : <></>}
            {props.display === 'Stories' ? <div className='ml-1 mb-2px'><Icon icon={openBook} height={16} width={16} /></div> : <></>}
            {props.display === 'Bitcoin Stories' ? <div className='ml-1 mb-1'><Icon icon={bitcoinsvIcon} height={16} width={16} /></div> : <></>}
            {props.display === 'Letter Forever' ? <div className='ml-1'><Icon icon={textDocumentEdit} height={16} width={16} /></div> : <></>}
            {props.display === 'Poetry' ? <div className='ml-1'><Icon icon={diplomaScroll} height={16} width={16} /></div> : <></>}
            {props.display === 'Bounty Challenges' ? <div className=''><Icon icon={currencyUsd} height={18} width={18} /></div> : <></>}
        </label>
      </div>
    </>
  );
};

export default SelectCategoryRadioButton