import React from 'react'
import Modal from "react-modal";
import { globals } from '../../config/globals';
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';

const PaymentModal = props => {
  const {
    category,
    showPaymentModal,
    toggleModal,
    triggerPaymentFunction,
    transactionType,
    auth,
    thumbsCost,
    postTitle,
    categoryName,
    handCashPostCreator,
    commentsArray,
    commentIndex,
    paywallCost,
    userToPay,
    tipAmount,
    massTipAmount,
    userDatabaseData,
    costToCommentIsFree,
    numberOfEligibleTippers,
    // Excel
    excelSelectionType,
    onExcelSearchChange,
    // Vote
    pollOptionSelected,
  } = props;
  // Determines which modal to render
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 sm:w-9/12 xl:w-2/5 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  const getCategoryNameDisplay = (categoryName) => {
    let categoryNameDisplay
    switch (categoryName) {
      case 'communities':
        categoryNameDisplay = 'Communities'
        break
      case 'prompt-games':
        categoryNameDisplay = 'Prompt Games'
        break
      case 'stamp-you':
        categoryNameDisplay = 'Stamp You'
        break
      case 'meet-the-stampers':
        categoryNameDisplay = 'Meet the Stampers'
        break
      // Entertainment +
      case 'gaming':
        categoryNameDisplay = 'Twitch Gaming'
        break
      case 'music':
        categoryNameDisplay = 'Spotify Soundcloud'
        break
      case 'podcasts':
        categoryNameDisplay = 'Podcasts'
        break
      case 'teach':
        categoryNameDisplay = `Teach & Learn`
        break
      case 'comedy':
        categoryNameDisplay = 'Comedy Corner'
        break
      case 'sports':
        categoryNameDisplay = 'YouTube Sports'
        break
      case 'fitness':
        categoryNameDisplay = 'Insta Fitness'
        break
      case 'cooking':
        categoryNameDisplay = `Cooking`
        break
      case 'talent-showcase':
        categoryNameDisplay = 'Talent Showcase'
        break
      case 'animals':
        categoryNameDisplay = 'Animal Love'
        break
      case 'art':
        categoryNameDisplay = 'Art'
        break
      case 'beautiful-nature':
        categoryNameDisplay = 'Beautiful Nature'
        break
      case 'tiktok-playlist':
        categoryNameDisplay = 'TikTok Playlist'
        break
      case 'panoramic-reels':
        categoryNameDisplay = 'Panoramic Reels'
        break
      // Professional
      case 'entrepreneurship':
        categoryNameDisplay = 'Entrepreneurship'
        break
      case 'ama':
        categoryNameDisplay = 'AMA'
        break
      case 'career':
        categoryNameDisplay = 'Business & Career'
        break
      case 'interviews':
        categoryNameDisplay = 'Interviews'
        break
      case 'in-the-news':
        categoryNameDisplay = 'In The News'
        break
      case 'bitcoin-businesses':
        categoryNameDisplay = 'Bitcoin Businesses'
        break
      case 'promotions':
        categoryNameDisplay = 'Branded Promotions'
        break
      // Knowledge
      case 'tutorials':
        categoryNameDisplay = `How To Tutorials`
        break
      case 'articles':
        categoryNameDisplay = 'Articles'
        break
      case 'chatgpt':
        categoryNameDisplay = 'ChatGPT & AI'
        break
      case 'science':
        categoryNameDisplay = 'Science'
        break
      case 'reviews':
        categoryNameDisplay = 'Reviews'
        break
      case 'life':
        categoryNameDisplay = 'Life'
        break
      case 'health':
        categoryNameDisplay = 'Health & Diet'
        break
      case 'code-share':
        categoryNameDisplay = 'Code Share'
        break
      case 'books':
        categoryNameDisplay = 'Books'
        break
      // Bonus
      case 'polls':
        categoryNameDisplay = 'Polls'
        break
      case 'lists':
        categoryNameDisplay = 'Lists'
        break
      case 'questions':
        categoryNameDisplay = 'Questions'
        break
      case 'stories':
        categoryNameDisplay = 'Stories'
        break
        case 'bitcoin-stories':
          categoryNameDisplay = 'Bitcoin Stories'
          break
      case 'letter-forever':
        categoryNameDisplay = 'Letter Forever'
        break
      case 'poetry':
        categoryNameDisplay = 'Poetry'
        break
      case 'bounties':
        categoryNameDisplay = 'Bounty Challenges'
        break
      // Water cooler
      case 'water-cooler':
        categoryNameDisplay = 'Water Cooler'
        break
      // No longer used
      case 'bsv-news':
        categoryNameDisplay = 'BSV Apps & Business'
        break
      case 'prompts':
        categoryNameDisplay = 'Bounty Challenges'
        break
      case 'writing-games':
        categoryNameDisplay = 'Writing Games'
        break
      // Default
      default:
        categoryNameDisplay = 'Stamp You';
    }
    return categoryNameDisplay
  }
  switch (transactionType) {
    case 'THUMBS_FOR_POST':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl ml-6`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to give a thumbs up and send <span className={`text-brand-green font-bold`}>${parseFloat(thumbsCost).toFixed(2)}</span> to {handCashPostCreator} for their post entitled <span className='underline text-underline-under'>{postTitle}</span>
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={` text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'SHARE_POST':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to share this post with your followers by sending <span className={`text-brand-green font-bold`}>${globals.shareCost}</span> to {handCashPostCreator}.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'STAMPSHOT_POST':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to Stampshot this post to the Blockchain for <span className={`text-brand-green font-bold`}>
                  ${userDatabaseData?.membership
                    ? `${globals.memberStampshotCost}`
                    : `${globals.nonMemberStampshotCost}`
                  }
                </span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'SUBMIT_VOTE':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                {auth.handle === handCashPostCreator
                  ?
                  <>
                    <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                    please confirm you would like to vote <span className={`underline text-underline-under`}>{pollOptionSelected}</span> for your poll.
                  </>
                  :
                  <>
                    <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                    please confirm you would like to vote <span className={`underline text-underline-under`}>{pollOptionSelected}</span> for <span className={`text-brand-green font-bold`}>${globals.voteCost}</span>.
                  </>
                }
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'CREATE_POST':
      let postCost = '.10'
      // This is strictly for appearances and has no effect on the cost-to-post logic
      if (globals.freeCategoryCostArray.includes(categoryName)) postCost = '0'
      if (globals.fiveCentCategoryCostArray.includes(categoryName)) postCost = '.05'
      if (globals.twentyCentCategoryCostArray.includes(categoryName)) postCost = '.20'
      if (globals.twentyFiveCentCategoryCostArray.includes(categoryName)) postCost = '.25'
      if (globals.fiftyCentCategoryCostArray.includes(categoryName)) postCost = '.50'
      if (categoryName === 'promotions') postCost = globals.promotionsPrice
      if (categoryName === 'bitcoin-businesses') postCost = globals.bitcoinBusinessesPrice

      // This is strictly for appearances as well.
      const categoryNameDisplay = getCategoryNameDisplay(categoryName)
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                {/* If the category is Meet the Stampers */}
                {auth?.id && globals.freeCategoryCostArray.includes(categoryName)
                  ?
                  <>
                    <span className={``}> please confirm you would like to submit your content in <i>{categoryNameDisplay}</i>.</span>
                  </>
                  // If the user purchased membership
                  : userDatabaseData?.membership
                    ?
                    <>
                      <span className={``}> please confirm you would like to submit your content in <i>{categoryNameDisplay}</i>.</span>
                    </>
                    // If the user has not purchased membership, display the actual cost to post.
                    :
                    <>
                      <span className={``}> please confirm you would like to spend <span className={`text-brand-green font-bold`}>${postCost}</span> to earn 100% cut in <i>{categoryNameDisplay}</i>.</span>
                    </>
                }
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header 
                  ${postCost === '0' || userDatabaseData?.membership ? `p-2 gradient-background-green border-2 border-brand-green text-white shadow-effect-seven rounded-xl` : `connectButtonNoImage`}`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'UPDATE_POST':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like update the content of your post.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm py-1 px-2 font-header gradient-background-green shadow-effect-seven rounded-xl border-2 font-header text-white border-brand-green`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'UPDATE_COMMENT':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'Update Comment Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like update the content of your comment.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm py-1 px-2 font-header gradient-background-green shadow-effect-seven rounded-xl border-2 font-header text-white border-brand-green`}
                  onClick={(e) => triggerPaymentFunction(e, props.comment)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'CREATE_COMMENT':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center logo-style eleventh-text text-lg pb-2 teko-font text-3xl`}>
                {!costToCommentIsFree ? `HandCash ` : ``}Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to
                {props.category.includes(globals.listCategoryArray)
                  ? <span className='ml-1'>add an entry on the list entitled,</span>
                  : <span className='ml-1'>comment on the post entitled,</span>
                } <span className='underline text-underline-under'>{postTitle}</span>
                {!costToCommentIsFree
                  ?
                  <> for <span className={`text-brand-green font-bold`}>
                    ${userDatabaseData?.membership
                      ? `${globals.memberCommentCost}`
                      : category === 'lists'
                        ? `${globals.listCommentCost}`
                        : `${globals.commentCost}`
                    }
                  </span>.</>
                  : <>.</>
                }
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'THUMBS_FOR_COMMENT':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to give a thumbs up and send <span className={`font-bold text-brand-green`}>
                  ${parseFloat(thumbsCost).toFixed(2)}</span> to {commentsArray[commentIndex].commentCreatorHandle} for their comment.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e, commentIndex)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'PAYWALL_PURCHASE':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like purchase {handCashPostCreator}'s hidden content in <span className='underline text-underline-under'>{postTitle}</span> for <span className={`text-brand-green font-bold`}>${parseFloat(paywallCost).toFixed(2)}</span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'FOLLOW_USER':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to follow {userToPay} for <span className={`text-brand-green font-bold`}>${parseFloat(globals.followingCost).toFixed(2)}</span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'UNFOLLOW_USER':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                Unfollow Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to unfollow {userToPay}. Note: If you choose to unfollow and follow the user again in the future, another payment will be required. (Don't say we didn't warn you.)
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm py-1 px-2 font-header gradient-background-green shadow-effect-seven rounded-xl border-2 font-header text-white border-brand-green`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'TIP_USER':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to tip {userToPay} <span className={`text-brand-green font-bold`}>${parseFloat(tipAmount).toFixed(2)}</span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'MASS_TIP':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to conduct a Mass Tipping for
                <span className={`font-bold text-brand-green mx-1`}>
                  ${parseFloat(massTipAmount).toFixed(3)}
                </span>
                per account, for a total of:
                <span className={`font-bold text-brand-green ml-1`}>
                  ${parseFloat(massTipAmount * numberOfEligibleTippers).toFixed(3)}
                </span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'PURCHASE_ONE_YEAR_MEMBERSHIP':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to purchase a 1-Year Membership on {globals.platformName} for <span className={`text-brand-green font-bold`}>${globals.oneYearMembershipPrice.toFixed(2)}</span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                    text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'PURCHASE_LIFETIME_MEMBERSHIP':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'HandCash Payment Authorization Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                HandCash Confirmation
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to purchase a Lifetime membership on {globals.platformName} for <span className={`text-brand-green font-bold`}>${globals.lifeTimeMembershipPrice.toFixed(2)}</span>.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm font-header connectButtonNoImage`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                    text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'RATE_PAYWALL':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'Purchased Content Rating Modal'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                <Icon icon={closeCircleO} height={18} width={18} />
              </button>
              <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                Rating Purchased Content
              </div>
              <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
              <div className={`text-left text-sm py-2`}>
                <span className={`text-brand-green font-bold`}>{auth.handle}</span>,
                please confirm you would like to rate the purchased content {props.selectedStar} stars.
              </div>
              <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
              <div className={`flex justify-between mt-4`}>
                <button
                  className={`text-sm py-1 px-2 font-header gradient-background-green shadow-effect-seven rounded-xl border-2 font-header text-white border-brand-green`}
                  onClick={(e) => triggerPaymentFunction(e)}
                >
                  Confirm
                </button>
                <button className={`text-white gradient-background-red shadow-effect-seven
                  text-sm rounded-xl border-2 font-header border-red-700 p-2`}
                  onClick={(e) => toggleModal(e, '')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </>
      )
    case 'OPEN_EXCEL_MODAL':
      return (
        <>
          <Modal
            isOpen={showPaymentModal}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            className={styles}
            overlayClassName="modal-overlay mr-4 sm:mr-0"
            shouldReturnFocusAfterClose={true}
            onRequestClose={(e) => toggleModal(e, '')}
            closeTimeoutMS={3000}
            contentLabel={'Modal for Downloading Excel File'}
          >
            {/* Wrapping div */}
            <div className={``}>
              <form>
                <button onClick={(e) => toggleModal(e, '')} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
                  <Icon icon={closeCircleO} height={18} width={18} />
                </button>
                <div className={`text-center text-lg pb-2 teko-font text-3xl logo-style eleventh-text`}>
                  Generate Excel File to Download
                </div>
                <hr className={`sixth-border outsetBorder border mb-1 opacity-50`}></hr>
                <div className={`justify-center flex text-sm py-2 mt-3`}>
                  <select value={excelSelectionType} onChange={onExcelSearchChange}
                    className={`rounded p-2 w-auto
                    gradient-background-seven shadow-effect-ten border mr-2
                    outline-none rounded-md px-2 text-gray-900 transition 
                    `}
                  >
                    <option className={`hover:text-white`} name="2023">2023</option>
                    <option className={`hover:text-white`} name="2022">2022</option>
                    <option name="All">All</option>
                  </select>
                  <button
                    className={`text-sm py-1 px-2 gradient-background-green shadow-effect-seven mr-2
                    text-white border-brand-green
                    text-sm rounded-xl border-2 font-header`}
                    onClick={(e) => triggerPaymentFunction(e, excelSelectionType)}
                  >
                    Confirm
                  </button>
                  <button className={`text-white gradient-background-red shadow-effect-seven
                    text-sm rounded-xl border-2 font-header border-red-700 px-2`}
                    onClick={(e) => toggleModal(e, '')}
                  >
                    Cancel
                  </button>
                </div>
                <div className={`flex justify-between mt-4`}>

                </div>
              </form>
            </div>
          </Modal>
        </>
      )
    default: return <></>
  }
}

export default PaymentModal;