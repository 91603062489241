import React from 'react'
import Modal from "react-modal";
import SelectCategoryRadioButton from '../Widgets/SelectCategoryRadioButton';
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';
const CategoryModal = props => {
  const {
    toggleCategoryModal,
    showCategoryModal,
    isCategorySelected,
    handleRadioCategorySelection,
    submitCategoryForCreatePost,
    userDatabaseData,

  } = props;
  const styles = {
    base: `array-modal-content first-text gradient-background-four shadow-effect-five
    mt-6 w-full xl:w-3/4 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showCategoryModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="array-modal-overlay"
        onRequestClose={toggleCategoryModal}
        closeTimeoutMS={300}
        contentLabel={'Select Category Modal'}
      >
        <div className={`text-center`}>
          <button onClick={toggleCategoryModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
            <Icon icon={closeCircleO} height={18} width={18} />
          </button>
          <div className={`teko-font text-4xl mb-1 logo-style ml-6`}>
            Category Selection
          </div>
          <hr className={`sixth-border outsetBorder border mb-3 opacity-50`}></hr>
          {/* Confirmation */}
          <div className={`flex justify-center w-full`}>
            <button disabled={!isCategorySelected} className={`text-lg font-header border-2 rounded-xl p-2 sm:w-1/2 w-full transition
            ${!isCategorySelected ? `gradient-background-red border-red-700` : `gradient-background-green border-brand-green`}
              shadow-effect-seven text-white `}
              onClick={submitCategoryForCreatePost}
            >
              {!isCategorySelected
                ? `Choose Category`
                : `Confirm & Create`
              }
            </button>
          </div>
          <hr className={`sixth-border outsetBorder border mt-3 mb-2 opacity-50`}></hr>
          {/* Individuals */}
          <div className={`text-xl mb-1`}>
            Stamping
          </div>
          <div className={`mb-2 flex radio-container-two flex-wrap justify-center items-center`}>
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`prompt-games`} display={`Prompt Games`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`stamp-you`} display={`Stamp You`} />
            {userDatabaseData?.postedInMeetTheStampers
              ? <></>
              : <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`meet-the-stampers`} display={`Meet the Stampers`} />
            }
          </div>
          <hr className={`sixth-border outsetBorder border mb-2 opacity-50`}></hr>
          {/* Entertainment + */}
          <div className={`text-xl mb-1`}>
            Entertainment +
          </div>
          <div className={`mb-2 flex radio-container-two flex-wrap justify-center items-center`}>
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`gaming`} display={`Twitch Gaming`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`music`} display={`Spotify Soundcloud`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`podcasts`} display={`Podcasts`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`teach`} display={`Teach & Learn`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`comedy`} display={`Comedy Corner`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`sports`} display={`YouTube Sports`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`fitness`} display={`Insta Fitness`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`cooking`} display={`Cooking`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`talent-showcase`} display={`Talent Showcase`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`animals`} display={`Animal Love`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`art`} display={`Art`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`beautiful-nature`} display={`Beautiful Nature`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`tiktok-playlist`} display={`TikTok Playlist`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`panoramic-reels`} display={`Panoramic Reels`} />

          </div>
          <hr className={`sixth-border outsetBorder border mb-2 opacity-50`}></hr>
          {/* Professional */}
          <div className={`text-xl mb-1`}>
            Professional
          </div>
          <div className={`mb-2 flex radio-container-two flex-wrap justify-center items-center`}>
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`entrepreneurship`} display={`Entrepreneurship`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`ama`} display={`AMA`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`career`} display={`Business & Career`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`interviews`} display={`Interviews`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`in-the-news`} display={`In The News`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`bitcoin-businesses`} display={`Bitcoin Businesses`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`promotions`} display={`Branded Promotions`} />

          </div>
          <hr className={`sixth-border outsetBorder border mb-2 opacity-50`}></hr>
          {/* Knowledge */}
          <div className={`text-xl mb-1`}>
            Knowledge
          </div>
          <div className={`mb-2 flex radio-container-two flex-wrap justify-center items-center`}>
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`tutorials`} display={`How To Tutorials`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`articles`} display={`Articles`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`chat-gpt`} display={`ChatGPT & AI`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`science`} display={`Science`} />

            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`reviews`} display={`Reviews`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`life`} display={`Life`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`health`} display={`Health & Diet`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`code-share`} display={`Code Share`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`books`} display={`Books`} />
          </div>
          <hr className={`sixth-border outsetBorder border mb-2 opacity-50`}></hr>
          {/* Bonus */}
          <div className={`text-xl mb-1`}>
            Bonus
          </div>
          <div className={`mb-2 flex radio-container-two flex-wrap justify-center items-center`}>
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`polls`} display={`Polls`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`lists`} display={`Lists`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`questions`} display={`Questions`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`stories`} display={`Stories`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`bitcoin-stories`} display={`Bitcoin Stories`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`letter-forever`} display={`Letter Forever`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`poetry`} display={`Poetry`} />
            <SelectCategoryRadioButton handleChange={handleRadioCategorySelection} value={`bounties`} display={`Bounty Challenges`} />
          </div>
          <hr className={`sixth-border outsetBorder border mt-1 mb-3 opacity-50`}></hr>
          {/* Confirmation */}
          <div className={`flex justify-center w-full`}>
            <button disabled={!isCategorySelected} className={`text-lg font-header border-2 rounded-xl p-2 sm:w-1/2 w-full transition
            ${!isCategorySelected ? `gradient-background-red border-red-700` : `gradient-background-green border-brand-green`}
              shadow-effect-seven text-white `}
              onClick={submitCategoryForCreatePost}
            >
              {!isCategorySelected
                ? `Choose Category`
                : `Confirm & Create`
              }
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CategoryModal;
