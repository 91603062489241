import React from 'react';
import {
  Icon,
  stampIcon,
  postageStamp, 
  handshakeIcon,  
  vote20Regular,
  bulletListLine,
  codeLine,
  textDocumentEdit,
  musicInCollection,
  openBook,
  food16Filled,
  educationIcon,
  laughSquint,
  flowerIcon,
  twitchIcon,
  catIcon,
  myMoviesTV,
  brandTiktok,
  colorPaletteSolid,
  barbellIcon,
  basketballIcon,
  heartbeatIcon,
  bookSolid,
  personQuestionMark20Filled,
  knowledgeArticle,
  smileyO,
  helpInfoSolid,
  currencyUsd,
  podcastIcon,
  clipboardCheck,
  caretDown20Filled,
  sunSolid,
  moonSolid,
  brainCircuit20Filled,
  instagramIcon,
  diplomaScroll,
  atomSolid,
  bitcoinsvIcon,
  bellIcon,
  bxSearchAlt2,
  searchWeb 
  // ---- Professional category ----
  // briefcaseIcon,
  // newspaperIcon,
  // microphoneSolid,
  // talkBubblesLine,
  // lightbulbIcon,
  // applicationBraces,
  // adIcon
} from '../config/icons';

const Nav = (props) => {
  const {
    userDatabaseData,
    handleCheckedCheckbox,
    themeModeChecked,
    selectedCategory,
    faqPageClick,
    homePageClick,
    toggleModal,
    auth,
    goToProfilePage,
    localStorageAuth,
    rankingPageClick,
    massTippingPageClick,
    waterCoolerClick,
    logoutUser,
    membershipPageClick,
    notificationPageClick,
    privacyPageClick,
    searchPageClick,
    themeToggleDisabled,
    categoryHighlights,
    toggleIntroModal,
    toggleCategoryModal
  } = props;
  const UserMenu = (
    <div>
      <button className={`w-full text-left flex gradient-background-six mb-2px hover-rounded-lg-t rounded-t-lg`} onClick={goToProfilePage}><div className={`px-2 py-1 mt-2px`}>Profile Page</div></button>
      <hr className={`hr-border-dropdown outsetBorder border `}></hr>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={homePageClick}><div className={`px-2 py-1`}>View Feed</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={massTippingPageClick}><div className={`px-2 py-1`}>Mass Tipping</div></button>
      {userDatabaseData?.membership
        ? <>
          <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={waterCoolerClick}><div className={`px-2 py-1`}>Water Cooler</div></button>
          <hr className={`hr-border-dropdown outsetBorder border `}></hr>
        </>
        : <></>
      }
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={membershipPageClick}><div className={`px-2 py-1`}>Membership</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={rankingPageClick}><div className={`px-2 py-1`}>Rankings</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={faqPageClick}><div className={`px-2 py-1`}>FAQ</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={privacyPageClick}><div className={`px-2 py-1`}>Privacy</div></button>
      <hr className={`hr-border-dropdown outsetBorder border`}></hr>
      <button className={`w-full text-left flex gradient-background-six mt-2px hover-rounded-lg-b rounded-b-lg`} onClick={logoutUser}><div className={`px-2 py-1`}>Log Out</div></button>
    </div>
  )
  const NonUserMenu = (
    <div>
      <button className={`w-full text-left flex gradient-background-six mb-2px hover-rounded-lg-t rounded-t-lg`} onClick={toggleModal}><div className={`px-2 py-1 mt-2px`}>Join / Login</div></button>
      <hr className={`hr-border-dropdown outsetBorder border`}></hr>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={massTippingPageClick}><div className={`px-2 py-1`}>Mass Tipping</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={faqPageClick}><div className={`px-2 py-1`}>FAQ</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={membershipPageClick}><div className={`px-2 py-1`}>Membership</div></button>
      <button className={`w-full text-left flex gradient-background-six my-2px hover-rounded`} onClick={rankingPageClick}><div className={`px-2 py-1`}>Rankings</div></button>
      <button className={`w-full text-left flex gradient-background-six mt-2px hover-rounded-lg-b rounded-b-lg`} onClick={privacyPageClick}><div className={`px-2 py-1`}>Privacy</div></button>
    </div>
  );
  const EntertainmentCategoryMenu = (
    // Can also modify this once the sizes are determined. With margins, not padding.
    <div className={``}>
      {entertainmentCategories.map((category, index, { length }) => {
        return (
          <React.Fragment key={`${index}`}>
            {/* Different stylings with the length and index. First, middle, last */}
            <button className={`w-full text-center flex ${index % 2 !== 0 ? `gradient-background-two-hover` : `gradient-background-eleven`} 
            ${index === 0 ? `rounded-t-xl hover-rounded-xl-t:hover mt-0 mb-2px` : index + 1 === length ? `mt-2px mb-0 rounded-b-xl hover-rounded-xl-b:hover` : `my-2px hover-rounded`}
            ${categoryHighlights[index + 4].selectedClass ? `${categoryHighlights[index + 4].selectedClass}` : `shadow-effect-seven`}`}
              onClick={(e) => selectedCategory(e, index + 4, category.href)}
            >
              <div className={`${index === 0 ? `mt-2px` : ``} flex justify-center items-center px-2 py-1 rounded w-full h-full category-font-size`}>
                {category.categoryName}
                {category.categoryName === 'Twitch Gaming' ? <div className='ml-1'><Icon icon={twitchIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Spotify Soundcloud' ? <div className='ml-1'><Icon icon={musicInCollection} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Podcasts' ? <div className='ml-1'><Icon icon={podcastIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Teach & Learn' ? <div className='ml-1'><Icon icon={educationIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Comedy Corner' ? <div className='ml-1'><Icon icon={laughSquint} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'YouTube Sports' ? <div className='ml-1'><Icon icon={basketballIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Insta Fitness' ? <div className='ml-1'><Icon icon={barbellIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Cooking' ? <div className='ml-1'><Icon icon={food16Filled} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Talent Showcase' ? <div className='ml-1'><Icon icon={myMoviesTV} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Animal Love' ? <div className='ml-1'><Icon icon={catIcon} height={20} width={20} /></div> : <></>}
                {category.categoryName === 'Art' ? <div className='ml-1'><Icon icon={colorPaletteSolid} height={20} width={20} /></div> : <></>}
                {category.categoryName === 'Beautiful Nature' ? <div className='ml-1'><Icon icon={flowerIcon} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'TikTok Playlist' ? <div className='ml-1'><Icon icon={brandTiktok} height={20} width={20} /></div> : <></>}
                {category.categoryName === 'Panoramic Reels' ? <div className='ml-1'><Icon icon={instagramIcon} height={20} width={20} /></div> : <></>}
              </div>
            </button>
          </React.Fragment>
        )
      })}
    </div>
  )
  // const ProfessionalCategoryMenu = (
  //   <div className={``}>
  //     {professionalCategories.map((category, index, { length }) => {
  //       return (
  //         <React.Fragment key={`${index}`}>
  //           <button className={`w-full text-center flex ${index % 2 !== 0 ? `gradient-background-two-hover` : `gradient-background-eleven`} 
  //           ${index === 0 ? `rounded-t-xl hover-rounded-xl-t:hover mt-0 mb-2px` : index + 1 === length ? `mt-2px mb-0 rounded-b-xl hover-rounded-xl-b:hover` : `my-2px hover-rounded`}
  //           ${categoryHighlights[index + 18].selectedClass ? `${categoryHighlights[index + 18].selectedClass}` : `shadow-effect-seven`}`}
  //             onClick={(e) => selectedCategory(e, index + 18, category.href)}
  //           >
  //             <div className={`${index === 0 ? `mt-2px` : ``} flex justify-center items-center px-2 py-1 rounded w-full h-full category-font-size`}>
  //               {category.categoryName}
  //               {category.categoryName === 'Entrepreneurship' ? <div className='ml-1'><Icon icon={lightbulbIcon} height={18} width={18} /></div> : <></>}
  //               {category.categoryName === 'AMA' ? <div className='ml-1 mb-2px'><Icon icon={talkBubblesLine} height={18} width={18} /></div> : <></>}
  //               {category.categoryName === 'Business & Career' ? <div className='ml-1'><Icon icon={briefcaseIcon} height={16} width={16} /></div> : <></>}
  //               {category.categoryName === 'Interviews' ? <div className='ml-1'><Icon icon={microphoneSolid} height={18} width={18} /></div> : <></>}
  //               {category.categoryName === 'In The News' ? <div className='ml-1'><Icon icon={newspaperIcon} height={16} width={16} /></div> : <></>}
  //               {category.categoryName === 'Bitcoin Businesses' ? <div className='ml-1'><Icon icon={applicationBraces} height={18} width={18} /></div> : <></>}
  //               {category.categoryName === 'Branded Promotions' ? <div className='ml-1'><Icon icon={adIcon} height={18} width={18} /></div> : <></>}
  //             </div>
  //           </button>
  //         </React.Fragment>
  //       )
  //     })}
  //   </div>
  // )
  const KnowledgeCategoryMenu = (
    // Can also modify this once the sizes are determined. With margins, not padding.
    <div className={``}>
      {knowledgeCategories.map((category, index, { length }) => {
        return (
          <React.Fragment key={`${index}`}>
            {/* Index starts at 0, and gaming is the dropdown here, so we add 6 to it. */}
            <button className={`w-full text-center flex ${index % 2 !== 0 ? `gradient-background-two-hover` : `gradient-background-eleven`} 
            ${index === 0 ? `rounded-t-xl hover-rounded-xl-t:hover mt-0 mb-2px` : index + 1 === length ? `mt-2px mb-0 rounded-b-xl hover-rounded-xl-b:hover` : `my-2px hover-rounded`}
            ${categoryHighlights[index + 19].selectedClass ? `${categoryHighlights[index + 19].selectedClass}` : `shadow-effect-seven`}`}
              onClick={(e) => selectedCategory(e, index + 19, category.href)}
            >
              {/* Need to monkey with the padding x axis, once font-size has been determined */}
              <div className={`${index === 0 ? `mt-2px` : ``} flex justify-center items-center px-2 py-1 rounded w-full h-full category-font-size`}>
                {category.categoryName}
                {category.categoryName === 'How To Tutorials' ? <div className='ml-px'><Icon icon={helpInfoSolid} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Articles' ? <div className='ml-1'><Icon icon={knowledgeArticle} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'ChatGPT & AI' ? <div className='ml-1'><Icon icon={brainCircuit20Filled} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Science' ? <div className='ml-1'><Icon icon={atomSolid} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Reviews' ? <div className='ml-px'><Icon icon={clipboardCheck} height={20} width={20} /></div> : <></>}
                {category.categoryName === 'Life' ? <div className='ml-1'><Icon icon={smileyO} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Health & Diet' ? <div className='ml-1'><Icon icon={heartbeatIcon} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Code Share' ? <div className='ml-1'><Icon icon={codeLine} height={20} width={20} /></div> : <></>}
                {category.categoryName === 'Books' ? <div className='ml-1'><Icon icon={bookSolid} height={18} width={18} /></div> : <></>}
              </div>
            </button>
          </React.Fragment>
        )
      })}
    </div>
  )
  const BonusCategoryMenu = (
    // Can also modify this once the sizes are determined. With margins, not padding.
    <div className={``}>
      {bonusCategories.map((category, index, { length }) => {
        return (
          <React.Fragment key={`${index}`}>
            {/* Index starts at 0, and gaming is the dropdown here, so we add 6 to it. */}
            <button className={`w-full text-center flex ${index % 2 !== 0 ? `gradient-background-two-hover` : `gradient-background-eleven`} items-center
            ${index === 0 ? `rounded-t-xl hover-rounded-xl-t:hover mt-0 mb-2px` : index + 1 === length ? `mt-2px mb-0 rounded-b-xl hover-rounded-xl-b:hover` : `my-2px hover-rounded`}
            ${categoryHighlights[index + 29].selectedClass ? `${categoryHighlights[index + 29].selectedClass}` : `shadow-effect-seven`}`}
              onClick={(e) => selectedCategory(e, index + 29, category.href)}
            >
              {/* Need to monkey with the padding x axis, once font-size has been determined */}
              <div className={`${index === 0 ? `mt-2px` : ``} px-2 py-1 flex justify-center items-center rounded w-full h-full category-font-size`}>
                {category.categoryName}
                {category.categoryName === 'Polls' ? <div className='ml-1 mb-1'><Icon icon={vote20Regular} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Lists' ? <div className='ml-1'><Icon icon={bulletListLine} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Questions' ? <div className='ml-1'><Icon icon={personQuestionMark20Filled} height={18} width={18} /></div> : <></>}
                {category.categoryName === 'Stories' ? <div className='ml-1 mb-2px'><Icon icon={openBook} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Bitcoin Stories' ? <div className='ml-1 mb-px'><Icon icon={bitcoinsvIcon} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Letter Forever' ? <div className='ml-1'><Icon icon={textDocumentEdit} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Poetry' ? <div className='ml-1'><Icon icon={diplomaScroll} height={16} width={16} /></div> : <></>}
                {category.categoryName === 'Bounty Challenges' ? <div className=''><Icon icon={currencyUsd} height={18} width={18} /></div> : <></>}
              </div>
            </button>
          </React.Fragment>
        )
      })}
    </div>
  )
  return (
    <>
      {/* Container Div for Flexbox */}
      <header className={`w-full sm:flex transition py-1 px-2 mb-8 gradient-background-two shadow-effect-five`}>
        {/* Left Side */}
        <div className={`items-center hidden text-xs md:flex md:flex-1 md:justify-start`}>
          <div className={`p-1 -m-1`}>
           {/* Digital Content Publishing Network */}
            <button onClick={toggleIntroModal}
              className={`flex items-center third-text font-header gradient-background-twelve shadow-effect-eight text-sm px-6 py-2px rounded-2xl`}>
                <div className={`text-center`}>
                  <div className={`logo-style py-2px`}>
                    {/* <span className={`text-brand-green`}>[ </span>  */}
                    TipStampers Publishing Network
                    {/* <span className={`text-brand-green`}> ]</span> */}
                  </div>
                  <hr className={`border-brand-green outsetBorder border`}></hr>
                  <div className={`text-xs pt-2px`}>
                    Creator Communities earning <span className={`text-brand-green`}>100% cut</span>
                  </div>
                  <div className={`text-xs pb-2px`}>
                    Click<span className={`fifth-text`}> here </span>for more info
                  </div>
                </div>
            </button>
          </div>
        </div>
        {/* Center */}
        <div className={``}>
          <h1 className={`-mb-1 justify-start text-center sm:flex sm:flex-1 sm:justify-center text-size-header teko-font second-text`}>
            <button className={`logo-style second-text`} onClick={homePageClick}>
              Tip<span className={`eleventh-text`}>Stampers</span>.com
            </button>
          </h1>
          {/* Intro modal again, this time on mobile */}
          <div className={`sm:hidden text-center -mt-1 mb-6`}>
            {/* Digital Content Publishing Network */}
            <button onClick={toggleIntroModal} className={`third-text text-sm font-header gradient-background-twelve shadow-effect-eight px-6 py-2px rounded-2xl`}>
              <div className={`text-center`}>
                <div className={`logo-style text-base py-1`}>
                  TipStampers Publishing Network
                </div>
                <hr className={`border-brand-green outsetBorder border`}></hr>
                <div className={`text-sm pt-1`}>
                  Creator Communities earning <span className={`text-brand-green font-bold`}>100% cut</span>
                </div>
                <div className={`text-sm mb-1`}>
                  Tap<span className={`fifth-text`}> here </span>for more info
                </div>
              </div>
            </button>
          </div>
        </div>
        {/* Right Side */}
        <div className={`flex flex-wrap pb-4 sm:pb-0 -mb-2 text-sm sm:text-base sm:flex-1 sm:justify-end items-center justify-center`}>
          <div className={`mr-2 mb-2 rounded-xl shadow-effect-five`}>
            {/* Theme Toggler */}
            <input
              aria-label="Light/Dark mode toggle switch"
              tabIndex='-1'
              type="checkbox"
              className="checkbox"
              id="chk"
              checked={themeModeChecked}
              onChange={handleCheckedCheckbox}
              disabled={themeToggleDisabled}
            />
            <label className={`label transition eighth-text gradient-background-twelve`} htmlFor="chk">
              <div className="moon"><Icon icon={moonSolid} height={26} width={26} /></div>
              <div className="sun"><Icon icon={sunSolid} height={24} width={24} /></div>
              <div className={`ball`}>
                <div className="yingyang-circle">
                  <div className="yinyang"></div>
                  <div className="yinyang"></div>
                </div>
              </div>
            </label>
          </div>
          {/* Notification Menu */}
          {auth && localStorageAuth && auth.id && userDatabaseData
            ?
            <div className={`mr-2 mb-2`}>
              <button onClick={notificationPageClick}
                className={`flex items-center p-1 rounded-xl transition shadow-effect-five eighth-text gradient-background-twelve
                button-ripple h-10`}>
                <div className={`py-2px flex items-center`}>
                  <span className={`mx-1 py-px`} >
                    <Icon icon={bellIcon} height={26} width={24} />
                  </span>
                </div>
                {userDatabaseData && userDatabaseData.numNewNotifications > 0
                  ? userDatabaseData.numNewNotifications > 99
                    ?
                    <div className={`mr-5px flex justify-center text-xs text-white red-notification-button rounded-xl min-width-22px 
                      py-2px px-1 text-center`}>
                      99+
                    </div>
                    :
                    <div className={`mr-5px flex justify-center text-xs text-white red-notification-button rounded-xl min-width-22px 
                      py-2px px-1 text-center`}>
                      {userDatabaseData.numNewNotifications}
                    </div>
                  :
                  <>
                    {/* If you want to see the 0... */}
                    {/* <div className={`mr-5px flex justify-center text-xs text-white red-notification-button rounded-xl min-width-22px 
                    py-2px px-1 text-center`}>
                    {userDatabaseData.numNewNotifications}
                  </div> */}
                  </>
                }
              </button>
            </div>
            : <></>
          }
          {/* Magnifying Glass redirect to search page*/}
          <div className={`mr-2 mb-2 h-10 rounded-xl shadow-effect-five`}>
            <button
              onClick={searchPageClick}
              className={`items-center transition rounded-xl button-ripple eighth-text gradient-background-twelve h-10 w-10`}>
              <Icon className={`ml-2`} icon={bxSearchAlt2} height={26} width={26} />
            </button>
          </div>
          {/* Dropdown */}
          <div data-dropdown className={`dropdown font-header teko-font menu-font-size mb-2`}>
            <button data-dropdown-button className={`flex link menu-height items-center p-px menu-rounded 
              shadow-effect-five eighth-text gradient-background-twelve transition`}>
              {userDatabaseData && auth && localStorageAuth && auth.id ?
                <img data-dropdown-button className={`rounded-xl ml-1`}
                  width='32' height='32' alt="Avatar"
                  src={auth.avatarUrl}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/images/round-handcash-logo.png'
                  }}
                />
                : <></>
              } <span data-dropdown-button className={auth && localStorageAuth && auth.id ? `pl-1 pr-2 mt-px logo-style eleventh-text` : `px-3 py-2px mt-px logo-style eleventh-text`}>Menu</span>
            </button>
            <div className={`dropdown-menu category-font-size menu-big-shadow dropdown-top-nav w-max-no-query -right-0 text-sm font-header rounded-lg`}>
              {localStorageAuth && auth?.id ? UserMenu : NonUserMenu}
            </div>
          </div>
        </div>
        <div className={`flex w-full justify-center sm:hidden`}>
          <button onClick={(e) => toggleCategoryModal(e)}
            className={`third-text transition font-header flex items-center mt-2 mb-6 gradient-background-twelve 
              logo-style  shadow-effect-five h-10 w-11/12 justify-center py-2px rounded-2xl`}>
            Explore Categories
            <div className={`ml-px`}><Icon icon={searchWeb} height={30} width={30} /></div>
          </button>
        </div>
      </header>
      {/* Here is the category div */}
      <nav className={`hidden md:flex w-full md:justify-center transition mb-6 pb-1 pt-2px category-font-size`}>
        {navCategories.map((category, index) => {
          return (
            !category.isMultipleCategory
              ?
              <button aria-label='Go to category page' key={index} onClick={(e) => selectedCategory(e, category.indexLevel, category.href)}
                className={`font-header eighth-text gradient-background-eleven rounded-xl  
                ${categoryHighlights[category.indexLevel].selectedClass ? `${categoryHighlights[category.indexLevel].selectedClass}` : `shadow-effect-five`}
                button-ripple transition flex items-center category-hover mx-1 py-6px px-10px`
                }
              >
                {category.categoryName}
                {category.categoryName === 'Prompt Games' ? <span className={`ml-1`}><Icon icon={textDocumentEdit} height={16} width={16} /></span> : <></>}
                {category.categoryName === 'Stamp You' ? <span className={`ml-2px -mt-2px`}><Icon icon={stampIcon} height={19} width={19} /></span> : <></>}
                {category.categoryName === 'Meet the Stampers' ? <div className={`ml-1 mt-px`}><Icon icon={handshakeIcon} height={19} width={19} /></div> : <></>}
                {category.categoryName === 'All' ? <div className={`ml-3px mb-px`}><Icon icon={postageStamp} height={19} width={19} /></div> : <></>}
              </button>
              // This is where the dropdown goes.
              :
              <React.Fragment key={index}>
                <div data-dropdown className={`dropdown`}>
                  <button data-dropdown-button aria-label='Go to category page' key={index}
                    className={`font-header link eighth-text gradient-background-two-hover rounded-xl 
                    ${categoryHighlights[category.indexLevel].selectedClass ? `${categoryHighlights[category.indexLevel].selectedClass}` : `shadow-effect-five`}
                    button-ripple transition flex items-center category-hover mx-1 py-6px px-10px`
                    }
                  >
                    {category.categoryName}<div className={`ml-px -mr-6px arrow-color pointer-events-none`}><Icon icon={caretDown20Filled} height={17} width={17} /></div>
                  </button>
                  {/* When other dropdown categories are added, the class will be updated. No way to center */}
                  <div className={`dropdown-menu big-shadow dropdown-top-nav w-max-no-query text-sm font-header rounded-xl
                    ${category.categoryName === 'Entertainment +' ? `right-minus-10px` :
                      // category.categoryName === 'Professional' ? `right-minus-30px` :
                        category.categoryName === 'Knowledge' ? `right-minus-17px` :
                          category.categoryName === 'Bonus' ? `right-minus-40px` : ``}`
                  }>
                    {/* This is to be changed if there is more than one dropdown, besides the "Entertainment +" category */}
                    {category.categoryName === 'Entertainment +' ? EntertainmentCategoryMenu :
                      // category.categoryName === 'Professional' ? ProfessionalCategoryMenu :
                        category.categoryName === 'Knowledge' ? KnowledgeCategoryMenu :
                          category.categoryName === 'Bonus' ? BonusCategoryMenu : null
                    }
                  </div>
                </div>
              </React.Fragment>
          )
        })}
      </nav>
    </>
  );
};
const navCategories = [
  {
    indexLevel: 0,
    categoryName: 'Prompt Games',
    href: 'prompt-games',
    isMultipleCategory: false,
  },
  {
    indexLevel: 1,
    categoryName: 'Stamp You',
    href: 'stamp-you',
    isMultipleCategory: false,
  },
  {
    indexLevel: 2,
    categoryName: 'Meet the Stampers',
    href: 'meet-the-stampers',
    isMultipleCategory: false,
  },
  {
    indexLevel: 3,
    categoryName: 'Entertainment +',
    isMultipleCategory: true,
  },
  // {
  //   indexLevel: 18,
  //   categoryName: 'Professional',
  //   isMultipleCategory: true,
  // },
  {
    indexLevel: 18,
    categoryName: 'Knowledge',
    isMultipleCategory: true,
  },
  {
    indexLevel: 28,
    categoryName: 'Bonus',
    isMultipleCategory: true,
  },
  {
    indexLevel: 37,
    categoryName: 'All',
    href: 'all',
    isMultipleCategory: false,
  },
  // {
  //   indexLevel: 44,
  //   categoryName: 'Communities',
  //   href: 'communities',
  //   isMultipleCategory: false,
  // },
]
// 13 categories
const entertainmentCategories = [
  {
    categoryName: 'Twitch Gaming',
    href: 'gaming',
    selectedClass: '',
  },
  {
    categoryName: 'Spotify Soundcloud',
    href: 'music',
    selectedClass: ''
  },
  {
    categoryName: 'Podcasts',
    href: 'podcasts',
    selectedClass: ''
  },
  {
    categoryName: 'Teach & Learn',
    href: 'teach',
    selectedClass: ''
  },
  {
    categoryName: 'Comedy Corner',
    href: 'comedy',
    selectedClass: ''
  },
  {
    categoryName: 'YouTube Sports',
    href: 'sports',
    selectedClass: ''
  },
  {
    categoryName: 'Insta Fitness',
    href: 'fitness',
    selectedClass: ''
  },
  {
    categoryName: 'Cooking',
    href: 'cooking',
    selectedClass: ''
  },
  {
    categoryName: 'Talent Showcase',
    href: 'talent-showcase',
    selectedClass: ''
  },
  {
    categoryName: 'Animal Love',
    href: 'animals',
    selectedClass: ''
  },
  {
    categoryName: 'Art',
    href: 'art',
    selectedClass: ''
  },
  {
    categoryName: 'Beautiful Nature',
    href: 'beautiful-nature',
    selectedClass: ''
  },
  {
    categoryName: 'TikTok Playlist',
    href: 'tiktok-playlist',
    selectedClass: ''
  },
  {
    categoryName: 'Panoramic Reels',
    href: 'panoramic-reels',
    selectedClass: ''
  },
]
// const professionalCategories = [
//   {
//     categoryName: 'Entrepreneurship',
//     href: 'entrepreneurship',
//     selectedClass: ''
//   },
//   {
//     categoryName: `AMA`,
//     href: 'ama',
//     selectedClass: ''
//   },
//   {
//     categoryName: 'Business & Career',
//     href: 'career',
//     selectedClass: ''
//   },
//   {
//     categoryName: `Interviews`,
//     href: 'interviews',
//     selectedClass: ''
//   },
//   {
//     categoryName: 'In The News',
//     href: 'in-the-news',
//     selectedClass: ''
//   },
//   {
//     categoryName: 'Bitcoin Businesses',
//     href: 'bitcoin-businesses',
//     selectedClass: ''
//   },
//   {
//     categoryName: 'Branded Promotions',
//     href: 'promotions',
//     selectedClass: ''
//   }
// ]
const knowledgeCategories = [
  {
    categoryName: `How To Tutorials`,
    href: 'tutorials',
    selectedClass: ''
  },
  {
    categoryName: 'Articles',
    href: 'articles',
    selectedClass: ''
  },
  {
    categoryName: 'ChatGPT & AI',
    href: 'chatgpt',
    selectedClass: ''
  },
  {
    categoryName: 'Science',
    href: 'science',
    selectedClass: ''
  },
  {
    categoryName: 'Reviews',
    href: 'reviews',
    selectedClass: ''
  },
  {
    categoryName: `Life`,
    href: 'life',
    selectedClass: ''
  },
  {
    categoryName: `Health & Diet`,
    href: 'health',
    selectedClass: ''
  },
  {
    categoryName: 'Code Share',
    href: 'code-share',
    selectedClass: ''
  },
  {
    categoryName: 'Books',
    href: 'books',
    selectedClass: '',
  },
]
const bonusCategories = [
 
  {
    categoryName: 'Polls',
    href: 'polls',
    selectedClass: ''
  },
  {
    categoryName: 'Lists',
    href: 'lists',
    selectedClass: ''
  },
  {
    categoryName: 'Questions',
    href: 'questions',
    selectedClass: '',
  },
  {
    categoryName: 'Stories',
    href: 'stories',
    selectedClass: ''
  },
  {
    categoryName: 'Bitcoin Stories',
    href: 'bitcoin-stories',
    selectedClass: ''
  },
  {
    categoryName: 'Letter Forever',
    href: 'letter-forever',
    selectedClass: ''
  },
  {
    categoryName: 'Poetry',
    href: 'poetry',
    selectedClass: ''
  },
  {
    categoryName: 'Bounty Challenges',
    href: 'bounties',
    selectedClass: ''
  },
]

export default Nav;
